import { ReactNode } from 'react';
import {
    FilledFolderTreeInterface,
    FolderTreeInterface,
} from '~/interfaces/entities';
import { FolderItemTypes } from '~/constants/folders';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';
import FolderTree from '~/components/UIElements/FolderTree';

export interface TopicFolderTreeProps<TopicInterfaceDetailed> {
    structure: FilledFolderTreeInterface[];
    customItem: (topicData: TopicInterfaceDetailed) => ReactNode;
    showActions: boolean;
    expandedFolders: string[];
    expandAllFolders: boolean;
    onUpdateFolder: (folder: FolderTreeInterface) => Promise<void>;
    onDeleteFolder: (folderId: string) => void;
    onExpandFolder: (folderId: string) => void;
    onCreateFolderItem: (itemName: string, parentId: string) => void;
    onCreateSubfolder: (folderName: string, parentFolderId: string) => void;
    onMoveItem: ({ id, folderId }: { id: string; folderId: string }) => void;
}

const TopicFolderTree = ({
    structure,
    showActions,
    expandedFolders,
    expandAllFolders,
    customItem,
    onUpdateFolder,
    onDeleteFolder,
    onExpandFolder,
    onCreateFolderItem,
    onCreateSubfolder,
    onMoveItem,
}: TopicFolderTreeProps<TopicInterface>) => {
    return (
        <FolderTree
            structure={structure}
            customItem={customItem}
            showActions={showActions}
            showItemsCount
            expandAllFolders={expandAllFolders}
            folderItemsType={FolderItemTypes.TOPICS}
            expandedFolders={expandedFolders}
            onExpandFolder={onExpandFolder}
            onCreateSubfolder={onCreateSubfolder}
            onCreateFolderItem={onCreateFolderItem}
            onUpdateFolder={onUpdateFolder}
            onDeleteFolder={onDeleteFolder}
            onMoveItem={onMoveItem}
        />
    );
};

export default TopicFolderTree;

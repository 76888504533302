import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useReports from '~/modules/Reports/hooks/useReports';
import { reportServices } from '~/services';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { getErrorCode } from '~/utils/getErrorCode';
import {
    CreateReportSectionDTO,
    ReportSectionInterfaceDetailed,
    UpdateReportSectionDTO,
} from '~/interfaces/entities';
import useReportsStore from '~/modules/Reports/stores/reportsStore';

interface ReportSectionsHookInterface {
    focusedSection: string | null;
    handleFocusedSection: (sectionId: string | null) => void;
    handleCreateSection: (text: string) => void;
    onCreateSection: (section: ReportSectionInterfaceDetailed) => void;
    onRemoveSection: (sectionId: string) => void;
    onUpdateSection: (
        sectionId: string,
        payload: UpdateReportSectionDTO
    ) => void;
}

const useReportSections = (): ReportSectionsHookInterface => {
    const intl = useIntl();
    const [focusedSection, setFocusedSection] = useState<string | null>(null);

    const [handleLoadingSections, fetchSections, sections] = useReportsStore(
        useShallow((state) => [
            state.handleLoadingSections,
            state.fetchSections,
            state.sections,
        ])
    );

    const { selectedReport } = useReports();

    const handleCreateSection = (text: string) => {
        const payload = {
            report_id: selectedReport?.id as string,
            text,
            position: sections.length,
        };
        if (text) onCreateSection(payload);

        setFocusedSection(null);
    };

    const onCreateSection = async (section: CreateReportSectionDTO) => {
        handleLoadingSections(true);

        const [error, response] =
            await reportServices.createReportSection(section);

        handleLoadingSections(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        fetchSections();
    };

    const onRemoveSection = async (sectionId: string) => {
        handleLoadingSections(true);

        const [error, response] = await reportServices.deleteSection(sectionId);

        handleLoadingSections(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        fetchSections();
    };

    const onUpdateSection = async (
        sectionId: string,
        payload: UpdateReportSectionDTO
    ) => {
        handleLoadingSections(true);

        const [error, response] = await reportServices.updateSection(
            sectionId,
            payload
        );

        handleLoadingSections(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        fetchSections();
    };

    return {
        focusedSection,
        handleFocusedSection: setFocusedSection,
        handleCreateSection,
        onCreateSection,
        onRemoveSection,
        onUpdateSection,
    };
};

export default useReportSections;

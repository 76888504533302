import { useMemo, useState, FormEvent } from 'react';
import toast from 'react-hot-toast';
import { ChevronDown, X } from 'react-feather';
import { useIntl } from 'react-intl';
import {
    useFloatMenu,
    useOpenFolders,
    useTemporaryErrorDisplay,
} from '~/hooks';
import { findFolderPath } from '~/utils/traverseFolderTreeFunctions';
import { getErrorCode } from '~/utils/getErrorCode';
import {
    FilledFolderTreeInterface,
    FolderInterface,
} from '~/interfaces/entities';
import { foldersServices } from '~/services';
import BaseModal from '../../BaseModal';
import Button from '../../Button';
import FolderSelector from '../../FolderSelector';
import TextField from '../../TextField';

import styles from './CreateFolderModal.module.scss';

interface CreateFolderModalProp {
    initialValues: {
        name: string;
    };
    projectId: string;
    handleClose: () => void;
    folderStructure: FilledFolderTreeInterface[];
    initialExpandedFolders: string[];
    onCreateFolder?: () => void;
}

const CreateFolderModal = ({
    projectId,
    handleClose,
    onCreateFolder,
    folderStructure,
    initialExpandedFolders = [],
}: CreateFolderModalProp) => {
    const intl = useIntl();
    const selectorModal = useFloatMenu();
    const { error, handleError } = useTemporaryErrorDisplay();

    const [loading, setLoading] = useState(false);

    const { openedFolders, openFolder } = useOpenFolders(
        initialExpandedFolders
    );
    const [folder, setFolder] = useState<FolderInterface>({
        name: '',
        id: '',
        project_id: projectId,
    });

    const onFolderCreated = () => {
        toast.success(
            intl.formatMessage({ id: 'folder_created_successfully' })
        );

        setLoading(false);
        handleClose();

        onCreateFolder && onCreateFolder();
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!folder.name.trim()) {
            handleError({ folder: 'required_field' });
            return;
        }

        setLoading(true);
        const [error, response] = await foldersServices.createFolder({
            name: folder.name,
            project_id: projectId,
            ...(folder.parent_id && { parent_id: folder.parent_id }),
        });

        if (error) {
            handleError({ server: getErrorCode(response.error_code) });
            setLoading(false);
            return;
        }

        onFolderCreated();
    };

    const folderPath = useMemo(() => {
        if (!folder.parent_id) return '';

        const maxLength = 43;
        const folderPath = findFolderPath(
            folderStructure,
            folder.parent_id
        )?.pathLabel; // cut the path to avoid overflow

        const shortPath = folderPath?.slice(-maxLength);

        return (
            `${folderPath?.length > maxLength ? '...' : ''}${shortPath}` || ''
        );
    }, [folder.parent_id]);

    const inputNameProps = {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setFolder({ ...folder, name: e.target.value }),
        value: folder.name,
        autoFocus: true,
        placeholder: 'e.g. Internal Subjects', //to translate?
    };

    const inputLocationProps = {
        value: folderPath,
        onClick: selectorModal.handleMenu,
        placeholder: 'Root', //to translate?
    };

    const errorInputName =
        error?.folder && intl.formatMessage({ id: error.folder });

    const errorInputLocation =
        error?.location && intl.formatMessage({ id: error.location });

    return (
        <BaseModal handleClose={handleClose} size="small">
            <div className={styles.cgCreateFolder}>
                <div className={styles.cgCreateFolder__header}>
                    <h4>
                        {intl.formatMessage({
                            id: 'create_folder',
                        })}
                    </h4>
                    <X onClick={handleClose} />
                </div>

                <form
                    onSubmit={onSubmit}
                    className={styles.cgCreateFolder__form}
                >
                    <TextField
                        label={intl.formatMessage({ id: 'folder_name' })}
                        inputProps={inputNameProps}
                        error={errorInputName}
                    />
                    <TextField
                        label={intl.formatMessage({ id: 'folder_location' })}
                        iconAfter={ChevronDown}
                        inputProps={inputLocationProps}
                        error={errorInputLocation}
                    />
                    {error?.server && (
                        <div className="error-centered-div">
                            {intl.formatMessage({ id: error.server })}
                        </div>
                    )}
                    <Button variant="accent" type="submit" disabled={loading}>
                        {intl.formatMessage({ id: 'create' })}
                    </Button>
                </form>

                {selectorModal.showMenu && (
                    <BaseModal
                        showCloseIcon
                        handleClose={selectorModal.handleMenu}
                        noPadding
                    >
                        <FolderSelector
                            structure={folderStructure}
                            value={folder.parent_id}
                            onCancel={selectorModal.handleMenu}
                            expandedFolders={openedFolders}
                            onExpandFolder={openFolder}
                            onConfirm={(folderId) => {
                                setFolder({ ...folder, parent_id: folderId });
                                selectorModal.handleMenu();
                            }}
                            placeholder={`${intl.formatMessage({ id: 'search_folders' })}...`}
                            confirmButtonText={intl.formatMessage({
                                id: 'confirm_location',
                            })}
                        />
                    </BaseModal>
                )}
            </div>
        </BaseModal>
    );
};

export default CreateFolderModal;

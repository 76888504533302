import {
    createContext,
    useState,
    useEffect,
    PropsWithChildren,
    useMemo,
} from 'react';
import toast from 'react-hot-toast';
import { searchServices } from '~/services';
import { useTable, useColumnsFilterContext, useEntityContext } from '~/hooks';
import parseColumnValues from '~/utils/parseColumnValues';
import { ProjectContext } from '~/context/project';
import buildExpressionsPayload from '~/utils/buildExpressionsPayload';
import { getErrorCode } from '~/utils/getErrorCode';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import useLatestQueries from '~/hooks/useLatestQueries';
import {
    ConditionsPresentation,
    GlobalSearchPayloadInterface,
    SearchBlockInterface,
} from '~/modules/Search/types/search.interface';
import { useIntl } from 'react-intl';
import { validateBlockStructure } from '~/utils/traverseBlocksFunctions';
import { snippetMatchFilterKeys } from '~/modules/Search/helpers/matchFilterKeys';
import useSearchStore from '~/modules/Search/stores/searchStore';
import {
    SEARCH_RESULT_VIEWS,
    SEARCH_TYPES,
} from '~/modules/Search/types/search.enum';
import { useShallow } from 'zustand/react/shallow';
import { ParagraphSection } from '../types/search.interface';
import {
    SnippetsProviderInterface,
    SnippetMatchesInterface,
} from '../types/snippetsContext.interface';
import { buildSortingParameters } from '~/helpers/insightsDataHandlers';
import { transformSearchBarFilters } from '../helpers/searchFiltersHelpers';

export const SnippetsContext = createContext<SnippetsProviderInterface | null>(
    null
);

const SnippetsProvider = ({ children }: PropsWithChildren) => {
    const intl = useIntl();
    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { saveLocalQuery, latestQueries } = useLatestQueries();

    // Conditions
    const [blocks, setBlocks] = useState<SearchBlockInterface[]>([]); // micro-blocks
    const [selectedBlock, setSelectedBlock] = useState<string | null>(null);

    const [loading, setLoading] = useState(false);

    // view for manual searches (table or list)
    const [resultsView, setResultsView] = useState<SEARCH_RESULT_VIEWS>(
        SEARCH_RESULT_VIEWS.TABLE
    );
    const [searchType, filters, handleFilters, resetSearchbarFilters] =
        useSearchStore(
            useShallow((state) => [
                state.searchType,
                state.filters,
                state.handleFilters,
                state.resetFilters,
            ])
        );

    // SNIPPETS TABLE DATA
    const [snippets, setSnippets] = useState<ParagraphSection[]>([]);
    const [matches, setMatches] = useState<SnippetMatchesInterface>({
        coincidences: 0,
        documents: 0,
    });

    // Documents related to snippets
    const [totalDocumentsIds, setTotalDocumentsIds] = useState<string[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const {
        pageSize,
        pageNumber,
        selectedItems,
        totalPages,
        totalItems,
        onPageChange,
        onSelectItem,
        setTotalItems,
    } = useTable({
        defaultPageSize: 50,
        defaultPageNumber: 0,
        multipleSelect: true,
    });

    // filters by column
    const {
        columnValues,
        columnFilters,
        handleColumnValues,
        onApplyFilter,
        sortParams,
        resetAllFilters,
        filterParams,
        resetFilterColumn,
    } = useColumnsFilterContext();

    useEffect(() => {
        // for infinite scroll
        if (pageNumber === 0 || pageNumber + 1 > totalPages) return;
        getMoreResults();
    }, [pageNumber]);

    // detect when user applies filters
    useEffect(() => {
        if (
            searchType !== SEARCH_TYPES.MANUAL ||
            resultsView !== SEARCH_RESULT_VIEWS.LIST
        )
            return;

        onSearch(true);
    }, [columnFilters, sortParams]);

    useEffect(() => {
        resetSearchbarFilters();
        setBlocks([]);
    }, [selectedProject]);

    const getConditions = () => {
        if (!blocks.length) return {};

        return {
            micro_conditions: buildExpressionsPayload(blocks),
        };
    };

    const onSearch = async (
        isFiltered: boolean,
        savedSearch?: GlobalSearchPayloadInterface
    ) => {
        // if search is new (isFiltered = false) but there are filters applied, we need to reset them
        if (!isFiltered && Object.keys(columnFilters).length) {
            resetAllFilters(); // reseting filters will launch a new search
            return;
        }

        setLoading(true);

        const projectId = selectedProject?.id as string;
        const searchBarFilters = transformSearchBarFilters(
            savedSearch?.filters || filters
        );
        const conditions = savedSearch?.conditions || getConditions();
        const tableFilters = isFiltered
            ? snippetMatchFilterKeys(filterParams)
            : {};
        const sorting = buildSortingParameters(sortParams);

        const [error, response] = await searchServices.searchParagraphs({
            page: 0,
            page_size: pageSize,
            project_ids: [projectId],
            ...tableFilters,
            ...sorting,
            ...conditions,
            ...searchBarFilters,
        });

        // store the query in localstorage to be suggested as Recent Query
        saveLocalQuery({
            project_id: projectId,
            micro_conditions:
                conditions.micro_conditions as ConditionsPresentation,
            ...(savedSearch?.filters || filters),
        });

        setLoading(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        const { data, paging } = response;
        const parsedColumnValues = parseColumnValues({
            section_page_number: data.not_post_filtered_section_page_number,
            document_name: data.not_post_filtered_document_name,
            tag_name: data.not_post_filtered_document_tag_name,
        });

        handleColumnValues(parsedColumnValues);

        const unpagedDocuments = Object.values(data.document_name).flat();
        setTotalDocumentsIds(unpagedDocuments);

        setMatches({
            coincidences: paging.total,
            documents: data.document_count,
        });
        setTotalItems(paging.total);
        setSnippets(data.sections || []);

        onSelectItem('');
        setSelectedAll(false);
        if (pageNumber !== 0) onPageChange(0);
    };

    const getMoreResults = async () => {
        setLoading(true);

        const sorting = buildSortingParameters(sortParams);
        const conditions = buildExpressionsPayload(blocks);
        const projectId = [selectedProject?.id as string];
        const tableFilters = snippetMatchFilterKeys(filterParams);

        const [error, response] = await searchServices.searchParagraphs({
            page: pageNumber,
            page_size: pageSize,
            project_ids: projectId,
            micro_conditions: conditions,
            ...sorting,
            ...tableFilters,
            ...filters,
        });

        setLoading(false);

        if (error) return;
        const { data } = response;
        const newSnippets = [...snippets, ...data.sections];

        setSnippets(newSnippets);
        if (selectedAll)
            onSelectItem(newSnippets.map((snippet) => snippet.es_section_id));
    };

    const resetInput = () => {
        setBlocks([]);
        handleFilters({
            case_sensitive: false,
            exact_match: false,
        });
    };

    const searchIsValid = useMemo(() => {
        if (loading || !selectedProject) return false;

        const isValid = validateBlockStructure(blocks);

        return isValid;
    }, [loading, selectedProject, blocks]);

    return (
        <SnippetsContext.Provider
            value={{
                loading,
                matches,
                snippets,
                totalDocumentsIds,
                blocks,
                selectedBlock,
                resultsView,
                searchIsValid,
                latestQueries,
                paging: {
                    selectedItems,
                    selectedAll,
                    onPageChange,
                    setSelectedAll,
                    onSelectItem,
                    pageNumber,
                    totalItems,
                },
                filtering: {
                    columnValues,
                    columnFilters,
                    sortParams,
                    resetAllFilters,
                    resetFilterColumn,
                    onApplyFilter,
                },
                setBlocks,
                setSelectedBlock,
                setLoading,
                setResultsView,
                onSearch,
                resetInput,
            }}
        >
            {children}
        </SnippetsContext.Provider>
    );
};

export default SnippetsProvider;

// export given string to .txt file
const exportStringToTxt = (string: string, filename: string) => {
    const blob = new Blob([string], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.click();
};

export default exportStringToTxt;

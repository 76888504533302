import { useState } from 'react';
import './UpdateReportDialog.scss';
import { BaseModal, Button, TextField } from '~/components/UIElements';
import { X } from 'react-feather';
import { toast } from 'react-hot-toast';
import { reportServices } from '~/services';
import { useIntl } from 'react-intl';

const UpdateReportDialog = ({ reportToEdit, callback, handleClose }) => {
    const intl = useIntl();

    const [report, setReport] = useState(reportToEdit);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!report.name.trim()) {
            handleError({ report: 'required_field' });
            return;
        }

        setLoading(true);

        const [error] = await reportServices.updateReport(report.id, {
            name: report.name,
        });

        setLoading(false);

        if (error) {
            handleError({ server: 'general_error' });
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'report_updated_successfully' })
        );

        if (callback) callback();
    };

    const handleError = (err) => {
        setError(err);
        setTimeout(() => {
            setError(null);
        }, [3000]);
    };

    return (
        <BaseModal size="small" handleClose={handleClose}>
            <div className="cg-update-report-dialog">
                <div>
                    <h4> {intl.formatMessage({ id: 'rename_report' })} </h4>
                    <X onClick={handleClose} />
                </div>
                <form onSubmit={handleSubmit}>
                    <TextField
                        inputProps={{
                            onChange: (e) =>
                                setReport({ ...report, name: e.target.value }),
                            value: report.name,
                            autoFocus: true,
                            placeholder: 'e.g. Economics',
                        }}
                        error={
                            error?.report &&
                            intl.formatMessage({ id: error.report })
                        }
                    />

                    {error?.server && (
                        <div className="error-centered-div">
                            {intl.formatMessage({ id: error.server })}
                        </div>
                    )}

                    <Button variant="accent" type="submit" disabled={loading}>
                        {intl.formatMessage({ id: 'rename' })}
                    </Button>
                </form>
            </div>
        </BaseModal>
    );
};

export default UpdateReportDialog;

import {
    InsightInterface,
    ReportSectionInterfaceDetailed,
} from '~/interfaces/entities';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';

const groupSourcesByTopic = (
    section: ReportSectionInterfaceDetailed,
    topics: TopicInterface[]
) => {
    const sources: { [key: string]: InsightInterface[] } = {};
    section.comments.forEach((comment) => {
        const topic = topics.find((t) => t.id === comment.topic_id)?.name;

        if (topic) {
            if (!sources[topic]) sources[topic] = [];

            sources[topic].push(comment);
        }
    });

    return sources;
};

export default groupSourcesByTopic;

export enum SEARCH_SPECIAL_SELECTORS {
    TOPIC = 'topic',
}

export enum SEARCH_OPERATORS {
    AND = 'AND',
    OR = 'OR',
    NOT = 'NOT',
}

export enum BLOCK_TYPES {
    text = 'text',
    operator = 'operator',
    expression = 'expression',
}

export enum MAP_RAW_TYPES {
    term = 'text',
    expression = 'expression',
    operator = 'operator',
}

export enum TERM_TYPE {
    EXPRESSION = 'expression',
    TERM = 'term',
}

export enum SEARCH_TYPES {
    MANUAL = 'manual',
    AI = 'ai',
}

export enum SEARCH_RESULT_VIEWS {
    LIST = 'list',
    TABLE = 'table',
}

export enum SAVED_SEARCH_TYPES {
    OPERATORS = 'operators',
    AI = 'ai',
}
export enum DISPLAY_CONDITIONS {
    parenthesis = 'parenthesis',
    text = 'text',
    operators = 'operators',
}

import {
    createContext,
    useState,
    useEffect,
    useCallback,
    useRef,
    PropsWithChildren,
} from 'react';
import { useIntl } from 'react-intl';
import { useTable, useColumnsFilterContext, useEntityContext } from '~/hooks';
import { searchServices } from '~/services';
import { SnippetsContext } from '~/modules/Search/contexts/SnippetsContext';
import { ProjectContext } from '~/context/project';
import buildExpressionsPayload from '~/utils/buildExpressionsPayload';
import parseColumnValues from '~/utils/parseColumnValues';
import { accumulateDates } from '~/utils/accumulateDates';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import {
    SEARCH_RESULT_VIEWS,
    SEARCH_TYPES,
} from '~/modules/Search/types/search.enum';
import useLatestQueries from '~/hooks/useLatestQueries';
import { CGDocumentInterfaceDetailed } from '~/interfaces/entities';
import { SourcesProviderInterface } from '~/modules/Search/types/sourcesContext.interface';
import { documentMatchFilterKeys } from '~/modules/Search/helpers/matchFilterKeys';
import useSearchStore from '~/modules/Search/stores/searchStore';
import {
    ConditionsPresentation,
    GlobalSearchPayloadInterface,
} from '~/modules/Search/types/search.interface';
import { SnippetsProviderInterface } from '../types/snippetsContext.interface';
import { buildSortingParameters } from '~/helpers/insightsDataHandlers';
import { transformSearchBarFilters } from '~/modules/Search/helpers/searchFiltersHelpers';

export const SourcesContext = createContext<SourcesProviderInterface | null>(
    null
);
// This context manage the states for the micro search (1st level) under the "file view"
const SourcesProvider = ({ children }: PropsWithChildren) => {
    const intl = useIntl();

    const filters = useSearchStore((state) => state.filters);
    const handleSearchType = useSearchStore((state) => state.handleSearchType);

    const {
        blocks,
        loading,
        setLoading,
        resultsView,
        onSearch: onSearchList,
    } = useEntityContext<SnippetsProviderInterface>(SnippetsContext);

    const { saveLocalQuery } = useLatestQueries();

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [sources, setSources] = useState<CGDocumentInterfaceDetailed[]>([]);

    const isInitialRender = useRef(true);

    const {
        pageSize,
        pageNumber,
        selectedItems,
        totalPages,
        onPageChange,
        setTotalPages,
        onSelectItem,
        setSelectedAll,
        selectedAll,
        setTotalItems,
        setSelectedItems,
        totalItems,
    } = useTable({
        defaultPageNumber: 0,
        multipleSelect: true,
    });

    const {
        columnValues,
        columnFilters,
        sortParams,
        filterParams,
        setColumnFilters,
        handleColumnValues,
        resetFilterColumn,
        onApplyFilter,
        resetAllFilters,
    } = useColumnsFilterContext();

    useEffect(() => {
        if (isInitialRender.current) return;
        onSearch(true);
    }, [columnFilters, sortParams]);

    useEffect(() => {
        isInitialRender.current = false;
        if (pageNumber === 0 || pageNumber + 1 > totalPages) return;
        getMoreResults();
    }, [pageNumber]);

    const getConditionsPayload = useCallback(() => {
        if (!blocks.length) return {};
        return {
            micro_conditions: buildExpressionsPayload(blocks),
        };
    }, [blocks]);

    const onSearch = async (
        isFiltered: boolean,
        savedSearch?: GlobalSearchPayloadInterface,
        resetSelection = true
    ) => {
        if (!isFiltered && Object.keys(columnFilters).length) {
            resetAllFilters(); // reseting filters will launch a new search
            return;
        }
        setLoading(true);
        handleSearchType(SEARCH_TYPES.MANUAL);

        const projectId = selectedProject?.id as string;
        const searchBarFilters = transformSearchBarFilters(
            savedSearch?.filters || filters
        );
        const conditions = savedSearch?.conditions || getConditionsPayload();
        const tableFilters = isFiltered
            ? documentMatchFilterKeys(filterParams)
            : {};
        const sorting = buildSortingParameters(sortParams);

        const [error, response] = await searchServices.searchDocuments({
            page: 0,
            page_size: pageSize,
            project_ids: [projectId],
            ...tableFilters,
            ...sorting,
            ...conditions,
            ...searchBarFilters,
        });

        // store the query in localstorage to be suggested as Recent Query
        saveLocalQuery({
            project_id: projectId,
            micro_conditions:
                conditions.micro_conditions as ConditionsPresentation,
            ...(savedSearch?.filters || filters),
        });

        setLoading(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        // reset selected items
        if (resetSelection) onSelectItem('');

        const { data, paging } = response;
        const parsedColumnValues = parseColumnValues({
            created_at: accumulateDates(data.post_filtered_document_created_at),
            page_count: data.post_filtered_document_page_number,
            name: data.post_filtered_document_name,
            tag_name: data.post_filtered_tag_name,
            user_name: data.post_filtered_document_user_name,
            filtered_document_ids: data.post_filtered_document_name,
        });

        handleColumnValues(parsedColumnValues);
        setTotalItems(paging.total);

        if (!data?.documents.length) {
            setSources([]);
            return;
        }

        setSources(data.documents);

        if (pageNumber !== 0) onPageChange(0);
    };

    // when user scroll touches the bottom, get more results
    const getMoreResults = async () => {
        setLoading(true);

        const conditions = getConditionsPayload();
        const tableFilters = documentMatchFilterKeys(filterParams);
        const sorting = buildSortingParameters(sortParams);

        const [error, response] = await searchServices.searchDocuments({
            page: pageNumber,
            page_size: pageSize,
            project_ids: [selectedProject?.id as string],
            ...conditions,
            ...tableFilters,
            ...sorting,
            ...filters,
        });

        setLoading(false);

        if (error) return;

        const { data } = response;
        setSources([...sources, ...data.documents]);
    };

    const handleSearch = (
        isFiltered?: boolean,
        payload?: GlobalSearchPayloadInterface,
        resetSelection = true
    ) => {
        if (resultsView === SEARCH_RESULT_VIEWS.TABLE) {
            onSearch(Boolean(isFiltered), payload, resetSelection);

            return;
        }

        onSearchList(Boolean(isFiltered), payload, resetSelection);
    };

    return (
        <SourcesContext.Provider
            value={{
                sources,
                loading,
                setLoading,
                paging: {
                    pageSize,
                    pageNumber,
                    selectedItems,
                    totalPages,
                    onPageChange,
                    setTotalPages,
                    setSelectedAll,
                    selectedAll,
                    onSelectItem,
                    totalItems,
                    setSelectedItems,
                },
                filtering: {
                    columnValues,
                    columnFilters,
                    sortParams,
                    filterParams,
                    setColumnFilters,
                    handleColumnValues,
                    resetFilterColumn,
                    onApplyFilter,
                    resetAllFilters,
                },
                onSearch,
                getMoreResults,
                handleSearch,
            }}
        >
            {children}
        </SourcesContext.Provider>
    );
};

export default SourcesProvider;

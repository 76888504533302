import {
    ReportInterface,
    ReportSectionInterfaceDetailed,
} from '~/interfaces/entities';
import { REPORT_LINK_OPTIONS } from '~/modules/Reports/constants/reports.enum';
import getReportSourcesText from '~/modules/Reports/helpers/getReportSourcesText';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import cognyLogoInBase64 from '~/assets/images/cognyLogoInBase64';
import { splitMarkdownInURL } from '~/modules/Reports/helpers/splitMarkdownInURL';

interface DownloadPPTReportParams {
    report: ReportInterface;
    topics: TopicInterfaceDetailed[];
    sections: ReportSectionInterfaceDetailed[];
    workspaceId: string;
    callback?: () => void;
}
const mainBlockConfig = {
    y: '10%',
    w: '100%',
    h: '100%',
    valign: 'top',
    align: 'center',
};

const reportNameConfig = {
    x: 1,
    y: '40%',
    w: '80%',
    h: 1,
    fontSize: 36,
    align: 'center',
    fill: { color: 'D3E3F3' },
    color: '008899',
};

const watermarkLogoConfig = {
    path: cognyLogoInBase64,
    w: '50%',
    h: '15%',
    x: '25%',
    y: '10%',
    transparency: 97,
    sizing: {
        type: 'contain',
    },
};

const watermarkConfig = {
    x: '42%',
    y: '9%',
    w: 1.5,
    transparency: 95,
};

const addSources = (
    section: ReportSectionInterfaceDetailed,
    topics: TopicInterfaceDetailed[],
    report: ReportInterface,
    workspaceId: string,
    presentation: any
) => {
    const src = getReportSourcesText(
        section,
        topics,
        report.project_id,
        workspaceId
    );

    // build text of section's sources
    const sourcesText = [];
    src.forEach((t, idx) => {
        sourcesText.push({
            text: t.rawText,
            options: {
                fontSize: 10,
                hyperlink: {
                    url: t.queryLink,
                },
            },
        });

        if (idx !== src.length - 1) sourcesText.push({ text: '   ' });
    });

    const slide = presentation.addSlide();

    slide.addText(
        [
            {
                text: section.text + '\n\n',
                options: {
                    fontSize: 10,
                    breakLine: true,
                },
            },
            ...sourcesText,
        ],
        mainBlockConfig
    );
};

const addConclusion = (conclusion: string, presentation: any) => {
    const { parsedConclusion, generatedLinks } = splitMarkdownInURL(
        conclusion,
        REPORT_LINK_OPTIONS.PPT
    );

    const slide = presentation.addSlide();

    const txts = [];

    for (let i = 0; i < parsedConclusion.length; i++) {
        txts.push({
            text: parsedConclusion[i],
            options: {
                fontSize: 10,
            },
        });

        if (generatedLinks[i]) {
            txts.push(generatedLinks[i]);
        }
    }

    slide.addText(txts, mainBlockConfig);
};

const downloadPPTReport = async ({
    report,
    topics,
    sections,
    workspaceId,
    callback,
}: DownloadPPTReportParams) => {
    // Dynamically import pptxgen
    const pptxgenModule = await import('pptxgenjs');
    const pptxgen = pptxgenModule.default || pptxgenModule;
    const presentation = new pptxgen();

    const slide = presentation.addSlide();

    // First slide - REPORT NAME
    slide.addText(report.name, reportNameConfig);

    // ADD water mark to first slide
    slide.addImage(watermarkLogoConfig);
    slide.addText('Powered by', watermarkConfig);

    // ADD SOURCES AND TEXT TO SLIDES
    sections.forEach(async (section, idx) => {
        addSources(section, topics, report, workspaceId, presentation);

        if (idx === sections.length - 1) {
            addConclusion(report.conclusion, presentation);

            // Save the Presentation
            await presentation.writeFile({
                fileName: `Cogny-Report-${report.name}`,
            });
            callback?.();
        }
    });
};

export default downloadPPTReport;

import {
    BLOCK_TYPES,
    MAP_RAW_TYPES,
    TERM_TYPE,
} from '~/modules/Search/types/search.enum';
import { SearchBlockInterface } from '~/modules/Search/types/search.interface';

export function updateBlockValue(blocks, id, newValue) {
    return blocks.map((block) => {
        if (block.id === id) {
            return { ...block, value: newValue, isPlaceholder: false };
        }

        if (block.content) {
            return {
                ...block,
                content: updateBlockValue(block.content, id, newValue),
            };
        }

        return block;
    });
}

export function findBlockAndAddContent(blocks, id, itemToAdd) {
    return blocks.map((block) => {
        if (block.id === id) {
            return { ...block, content: [...block.content, itemToAdd] };
        }

        if (block.content) {
            return {
                ...block,
                content: findBlockAndAddContent(block.content, id, itemToAdd),
            };
        }

        return block;
    });
}

export function removeBlock(
    blocks: SearchBlockInterface[],
    blockToRemove: string
): SearchBlockInterface[] {
    const newBlocks: SearchBlockInterface[] = [];

    blocks.forEach((block) => {
        if (block.id === blockToRemove) return;

        if (block.content) {
            newBlocks.push({
                ...block,
                content: removeBlock(block.content, blockToRemove),
            });
        } else {
            newBlocks.push(block);
        }
    });

    return newBlocks;
}

export function findAndUpdateBlockContent(blocks, id, newContent) {
    return blocks.map((block) => {
        if (block.id === id) {
            return { ...block, content: newContent };
        }

        if (block.content) {
            return {
                ...block,
                content: findAndUpdateBlockContent(
                    block.content,
                    id,
                    newContent
                ),
            };
        }

        return block;
    });
}

export function transformStructureFromRaw(rawStructure, isPlaceholder = false) {
    if (Object.keys(rawStructure).length === 0) return {};

    if (rawStructure.term_type === TERM_TYPE.EXPRESSION) {
        return {
            id: crypto.randomUUID(),
            type: MAP_RAW_TYPES[rawStructure.term_type],
            content: [
                transformStructureFromRaw(rawStructure.left, isPlaceholder),
                {
                    id: crypto.randomUUID(),
                    type: BLOCK_TYPES.operator,
                    value: rawStructure.operator,
                },
                transformStructureFromRaw(rawStructure.right, isPlaceholder),
            ],
        };
    }

    return {
        id: crypto.randomUUID(),
        type: MAP_RAW_TYPES[rawStructure.term_type],
        negative: rawStructure.negative,
        value: rawStructure.value,
        isPlaceholder,
    };
}

export function getParsedBlocks(payload, isPlaceholder = false) {
    if (!payload) return [];

    let content = [];
    if (payload.term_type === BLOCK_TYPES.expression) {
        content = [
            transformStructureFromRaw(payload.left, isPlaceholder),
            {
                id: crypto.randomUUID(),
                type: BLOCK_TYPES.operator,
                value: payload.operator,
            },
            transformStructureFromRaw(payload.right, isPlaceholder),
        ];
    } else {
        content = [transformStructureFromRaw(payload, isPlaceholder)];
    }

    return content;
}

export function validateBlockStructure(blocks: SearchBlockInterface[]) {
    if (blocks[0]?.type === BLOCK_TYPES.operator) {
        return false;
    }

    if (blocks.length % 2 === 0 || blocks.some((b) => b.isPlaceholder)) {
        return false;
    }

    for (const block of blocks) {
        if (block.type === BLOCK_TYPES.expression) {
            if (!validateBlockStructure(block.content)) {
                return false;
            }
        }
    }

    return true;
}

export function findParentBlock(blocks, id) {
    for (const block of blocks) {
        if (block.type === 'expression') {
            if (block.content.some((child) => child.id === id)) {
                return block;
            }

            const parentBlock = findParentBlock(block.content, id);
            if (parentBlock) {
                return parentBlock;
            }
        }
    }

    return null;
}

// I have to check if all blocks have no more than 3 children
export function validateBlocksLength(blocks) {
    if (blocks.length > 3) return false;
    for (const block of blocks) {
        if (block.content) {
            if (block.content.length > 3) {
                return false;
            }

            if (!validateBlocksLength(block.content)) {
                return false;
            }
        }
    }

    return true;
}

// check if a block belongs to a blockstructure
export function checkBlockInStructure(blocksStructure, blockId) {
    for (const block of blocksStructure) {
        if (block.id === blockId) {
            return true;
        }

        if (block.content) {
            if (checkBlockInStructure(block.content, blockId)) {
                return true;
            }
        }
    }

    return false;
}

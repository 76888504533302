import { useFloatMenu } from '~/hooks';
import { useIntl } from 'react-intl';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { BasePopover, Button } from '~/components/UIElements';
import { SparkIcon } from '~/assets/icons/shared';
import { Settings } from 'react-feather';
import SettingsMenu from '../SettingsMenu';
import RestrictedUI from '~/components/RestrictedUI';
import useReports from '~/modules/Reports/hooks/useReports';
import useReportsStore from '~/modules/Reports/stores/reportsStore';

import styles from './ConclusionHeader.module.scss';

const ConclusionHeader = () => {
    const intl = useIntl();
    const conclusionMenu = useFloatMenu();

    const { selectedReport, generateConclusion } = useReports();

    const [loading, loadingConclusion, AISettings, sections, handleAISettings] =
        useReportsStore((state) => [
            state.loading,
            state.loadingConclusion,
            state.AISettings,
            state.sections,
            state.handleAISettings,
        ]);

    const handleSettings = (name: string, value: string) => {
        handleAISettings({
            ...AISettings,
            [name]: value,
        });
    };

    return (
        <div className={styles.cgConclusionHeader}>
            <h4>{intl.formatMessage({ id: 'conclusion' })}</h4>

            {sections.length > 0 && (
                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.REPORTS}>
                    <div className={styles.cgConclusionHeader__actions}>
                        <Button
                            iconBefore={SparkIcon}
                            variant="secondary"
                            onClick={generateConclusion}
                            loading={loadingConclusion}
                            disabled={loadingConclusion || loading}
                        >
                            {intl.formatMessage({
                                id: selectedReport?.conclusion
                                    ? 'rewrite_with_ai'
                                    : 'write_with_ai',
                            })}
                        </Button>

                        <BasePopover
                            isOpen={conclusionMenu.showMenu}
                            positions={['bottom', 'top']}
                            content={
                                <SettingsMenu
                                    settings={AISettings}
                                    onChange={handleSettings}
                                />
                            }
                            onClickOutside={() => conclusionMenu.handleMenu()}
                        >
                            <Button
                                onClick={(e) => {
                                    conclusionMenu.handleMenu();
                                    e.stopPropagation();
                                }}
                                iconBefore={Settings}
                                variant="darken"
                                disabled={loadingConclusion}
                            >
                                {intl.formatMessage({
                                    id: 'optimize_ai',
                                })}
                            </Button>
                        </BasePopover>
                    </div>
                </RestrictedUI>
            )}
        </div>
    );
};

export default ConclusionHeader;

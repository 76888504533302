import { PillVariants } from '~/components/UIElements/Pill/constants';
import { SEARCH_OPERATORS } from '~/modules/Search/types/search.enum';

/**  Color overrides for PillSelectors
 * See https://gitlab.com/document_genome/applications/frontend/-/issues/404
  Search pills have their own coloring logic.
*/
enum SearchOperatorPillColorOverride {
    CARROT = `override-operator-${PillVariants.CARROT}`,
    PRIMARY = `override-operator-${PillVariants.PRIMARY}`,
    PETER = `override-operator-${PillVariants.PETER}`,
}

export const handleOperatorPillColor = (
    operator?: string
): SearchOperatorPillColorOverride | null => {
    if (operator === SEARCH_OPERATORS.AND) {
        return SearchOperatorPillColorOverride.PETER;
    }
    if (operator === SEARCH_OPERATORS.OR) {
        return SearchOperatorPillColorOverride.PRIMARY;
    }
    if (operator === SEARCH_OPERATORS.NOT) {
        return SearchOperatorPillColorOverride.CARROT;
    }

    return null;
};

enum SearchTagPillColorOverride {
    PRIMARY = `override-tag-${PillVariants.PRIMARY}`,
}

export const handleTagPillColor = (): SearchTagPillColorOverride => {
    return SearchTagPillColorOverride.PRIMARY;
};

import { Check, CheckCircle, ChevronRight, Icon } from 'react-feather';
import { useIntl } from 'react-intl';
import { DottedCircleIcon, MidDottedCircleIcon } from '~/assets/icons/shared';
import { BasicMenuItemInterface } from '~/components/UIElements/BasicMenuList/MenuItem';
import { TopicsContext } from '~/context/topics';
import { useEntityContext, useFloatMenu } from '~/hooks';
import useSidebarSortingMenu from '~/hooks/useSidebarSortingMenus.hook';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import TOPIC_STATUSES from '~/modules/Topics/constants/TopicStatuses.enum';
import getSortingFieldLabel from '~/helpers/getSortingFieldLabel';

interface UseTopicsSidebarFilterMenuResults {
    menus: BasicMenuItemInterface[];
}

interface UseTopicsSidebarFilterMenuProps {
    closeMainMenu: () => void;
    childrenMenuClassName?: string;
}

const useTopicsSidebarFilterMenu = ({
    closeMainMenu,
    childrenMenuClassName,
}: UseTopicsSidebarFilterMenuProps): UseTopicsSidebarFilterMenuResults => {
    const intl = useIntl();

    const { sorting, handleSorting, handleFilterByStatus, filterByStatus } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);
    const sortMenu = useFloatMenu();

    const { menus: sortingMenus } = useSidebarSortingMenu({
        sorting,
        handleSorting,
        closeMenu: () => {
            sortMenu.setShowMenu(false);
            closeMainMenu();
        },
    });

    const currentSortingLabel = getSortingFieldLabel(sorting);

    const MENUS = [
        {
            label: intl.formatMessage({ id: 'sort_by' }),
            iconAfter: ChevronRight,
            onClick: () => {},
            children: sortingMenus,
            childrenMenuClassName,
            suffixLegend:
                intl.formatMessage({ id: currentSortingLabel.translationKey }) +
                currentSortingLabel.suffixLabel,
        },
        {
            label: intl.formatMessage({ id: 'status' }),
            iconAfter: ChevronRight,
            onClick: () => {},
            children: [
                {
                    label: intl.formatMessage({ id: 'all' }),
                    onClick: () => {
                        handleFilterByStatus(null);
                        closeMainMenu();
                    },
                    iconAfter: !filterByStatus ? Check : undefined,
                },
                {
                    icon: DottedCircleIcon as Icon,
                    iconAfter:
                        filterByStatus === TOPIC_STATUSES.PENDING
                            ? Check
                            : undefined,
                    label: intl.formatMessage({ id: 'pending' }),
                    onClick: () => {
                        handleFilterByStatus(TOPIC_STATUSES.PENDING);
                        closeMainMenu();
                    },
                },
                {
                    icon: MidDottedCircleIcon as Icon,
                    iconAfter:
                        filterByStatus === TOPIC_STATUSES.IN_PROGRESS
                            ? Check
                            : undefined,
                    label: intl.formatMessage({ id: 'in_progress' }),
                    onClick: () => {
                        handleFilterByStatus(TOPIC_STATUSES.IN_PROGRESS);
                        closeMainMenu();
                    },
                },
                {
                    icon: CheckCircle,
                    iconAfter:
                        filterByStatus === TOPIC_STATUSES.DONE
                            ? Check
                            : undefined,
                    label: intl.formatMessage({ id: 'done' }),
                    onClick: () => {
                        handleFilterByStatus(TOPIC_STATUSES.DONE);
                        closeMainMenu();
                    },
                },
            ],
            childrenMenuClassName,
            suffixLegend: intl.formatMessage({ id: filterByStatus || 'all' }),
        },
    ];

    return { menus: MENUS };
};

export default useTopicsSidebarFilterMenu;

import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import {
    SIDEBAR_SORTING_FIELDS,
    SIDEBAR_SORTING_LABELS,
} from '~/constants/sidebarSortingKeys.enum';
import { EntitySortingParameters } from '~/interfaces/common';

interface SortingFieldLabelInterface {
    translationKey: string;
    suffixLabel: string;
}

const getSortingFieldLabel = (
    sorting: EntitySortingParameters
): SortingFieldLabelInterface => {
    let suffixLabel = '';
    let translationKey = '';

    if (sorting.field === SIDEBAR_SORTING_FIELDS.NAME) {
        translationKey = SIDEBAR_SORTING_LABELS.ALPHABETIC;
        suffixLabel =
            sorting.direction === SORT_TYPE_OPTIONS.ASC ? ' (A-Z)' : ' (Z-A)';
    }

    if (sorting.field === SIDEBAR_SORTING_FIELDS.CREATED_AT) {
        translationKey =
            sorting.direction === SORT_TYPE_OPTIONS.DESC
                ? SIDEBAR_SORTING_LABELS.NEWEST_FIRST
                : SIDEBAR_SORTING_LABELS.OLDEST_FIRST;
    }

    return {
        translationKey,
        suffixLabel,
    };
};

export default getSortingFieldLabel;

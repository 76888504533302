import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Download } from 'react-feather';
import { BlobProvider } from '@react-pdf/renderer';
import {
    BaseFloatMenu,
    BasePopover,
    IconButton,
} from '~/components/UIElements';
import styles from './ReportDownloadMenu.module.scss';
import { useShallow } from 'zustand/react/shallow';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import ExportableReport from '~/modules/Reports/components/ExportableReport';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import useReports from '~/modules/Reports/hooks/useReports';
import { useEntityContext } from '~/hooks';
import { TopicsContext } from '~/context/topics';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';

interface ReportDownloadMenuProps {
    downloadMenu: FloatMenuInterface;
    onDownloadPDF: (blob: Blob) => void;
    onDownloadRawText: () => void;
    onDownloadPPT: () => void;
}

const ReportDownloadMenu = ({
    downloadMenu,
    onDownloadPDF,
    onDownloadRawText,
    onDownloadPPT,
}: ReportDownloadMenuProps) => {
    const intl = useIntl();
    const downloadContainer = useRef();

    const { topics } = useEntityContext<TopicsProviderInterface>(TopicsContext);
    const { selectedWorkspace } = useWorkspaces();

    const [sections, loading, loadingSections] = useReportsStore(
        useShallow((state) => [
            state.sections,
            state.loading,
            state.loadingSections,
        ])
    );

    const { selectedReport } = useReports();

    return (
        <div ref={downloadContainer.current}>
            <BasePopover
                isOpen={downloadMenu.showMenu}
                onClickOutside={downloadMenu.handleMenu}
                parentContainer={downloadContainer.current}
                align="end"
                positions={['left']}
                content={
                    <BaseFloatMenu
                        top={5}
                        className={styles.cgReportDownloadMenu}
                    >
                        <BlobProvider
                            document={
                                <ExportableReport
                                    selectedReport={selectedReport!}
                                    sections={sections}
                                    topics={topics}
                                    workspaceId={selectedWorkspace!.id}
                                />
                            }
                        >
                            {({ blob }: { blob: Blob | null }) => (
                                <div
                                    className={
                                        styles.cgReportDownloadMenu__item
                                    }
                                    onClick={() => onDownloadPDF(blob!)}
                                >
                                    PDF
                                </div>
                            )}
                        </BlobProvider>
                        <div
                            className={styles.cgReportDownloadMenu__item}
                            onClick={onDownloadRawText}
                        >
                            {intl.formatMessage({
                                id: 'plain_text',
                            })}
                        </div>
                        <div
                            className={styles.cgReportDownloadMenu__item}
                            onClick={onDownloadPPT}
                        >
                            PowerPoint
                        </div>
                    </BaseFloatMenu>
                }
            >
                <IconButton
                    variant="secondary"
                    icon={Download}
                    onClick={downloadMenu.handleMenu}
                    disabled={loading || loadingSections || !sections.length}
                />
            </BasePopover>
        </div>
    );
};

export default ReportDownloadMenu;

import sanitizeHtml from 'sanitize-html';
const sanitizeConfig = {
    allowedTags: [
        'b',
        'i',
        'a',
        'p',
        'br',
        'ul',
        'li',
        'ol',
        'h1',
        'h2',
        'h3',
        'span',
    ],
    allowedAttributes: { a: ['href'] },
};
const sanitizeHtmlContent = (content: string) =>
    sanitizeHtml(content, sanitizeConfig);

export default sanitizeHtmlContent;

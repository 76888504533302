import React from 'react';
import { Plus, Trash2 } from 'react-feather';
import { Button } from '~/components/UIElements';
import { reportServices } from '~/services';
import { useIntl } from 'react-intl';
import { SourceInterface } from '~/interfaces/entities';
import './SourcesHeader.scss';

const mainClass: string = 'cg-sources-header';

interface SourcesHeaderProps {
    source: SourceInterface;
    loading: boolean;
    selectedItems: string[];
    handleLoading: (loading: boolean) => void;
    onDeleteSource: () => void;
    onAddSource: () => void;
    nonPaginatedIds: string[];
}

const SourcesHeader: React.FC<SourcesHeaderProps> = ({
    source,
    loading,
    selectedItems,
    nonPaginatedIds,
    handleLoading,
    onDeleteSource,
    onAddSource,
}) => {
    const intl = useIntl();

    const handleAddSource = async () => {
        handleLoading(true);

        const toRemove =
            source.commentIds?.filter((c) => !selectedItems.includes(c)) || [];

        const payload = {
            add_comment_ids: selectedItems,
            add_topic_ids: [],
            remove_comment_ids: toRemove,
            remove_topic_ids: [],
        };
        const [error] = await reportServices.updateSectionSources(
            source.section.id,
            payload
        );

        handleLoading(false);
        if (!error) onAddSource();
    };

    const handleDeleteSource = async () => {
        handleLoading(true);
        const payload = {
            add_comment_ids: [],
            add_topic_ids: [],
            remove_comment_ids: source.commentIds,
            remove_topic_ids: [],
        };

        const [error] = await reportServices.updateSectionSources(
            source.section.id,
            payload
        );

        handleLoading(false);

        if (!error) onDeleteSource();
    };

    const selectedAll = selectedItems.length === nonPaginatedIds.length;

    return (
        <div className={mainClass}>
            <h4>{intl.formatMessage({ id: 'select_source' })}</h4>

            <div>
                {!source.isNew && (
                    <Button
                        variant="secondary"
                        iconBefore={Trash2}
                        onClick={handleDeleteSource}
                        disabled={loading}
                    >
                        {intl.formatMessage({
                            id: 'remove_source',
                        })}
                    </Button>
                )}

                {selectedItems.length > 0 && (
                    <Button
                        variant="accent"
                        onClick={handleAddSource}
                        iconBefore={Plus}
                        disabled={loading}
                    >
                        {selectedAll
                            ? intl.formatMessage({
                                  id: 'select_all_rows',
                              })
                            : intl.formatMessage(
                                  {
                                      id:
                                          selectedItems.length > 1
                                              ? 'add_rows_as_source'
                                              : 'add_row_as_source',
                                  },
                                  { count: selectedItems.length }
                              )}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default SourcesHeader;

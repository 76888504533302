import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './SidebarHeader.module.scss';
import { PlusCircle, Search } from 'react-feather';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { ProjectContext } from '~/context/project';
import { useIntl } from 'react-intl';
import RestrictedUI from '~/components/RestrictedUI';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { BasePopover, BasicMenuList, TextField } from '~/components/UIElements';
import { SlidersIcon, StarMenuIcon } from '~/assets/icons/shared';
import CreateFolderModal from '~/components/UIElements/FolderTree/CreateFolderModal';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import classNames from 'classnames';
import useTopicsSidebarFilterMenu from './useTopicsSidebarFilterMenu.hook';

interface SidebarHeaderProps {
    lite: boolean;
    searchTerm: string;
    handleSearchTerm: (term: string) => void;
    handleTopicMenu: () => void;
    openSavedSearchesModal: () => void;
}

const SidebarHeader = ({
    lite,
    searchTerm,
    handleSearchTerm,
    handleTopicMenu,
    openSavedSearchesModal,
}: SidebarHeaderProps) => {
    const actionsContainer = useRef();

    const filterMenu = useFloatMenu();
    const folderMenu = useFloatMenu();

    const intl = useIntl();

    const [showSearch, setShowSearch] = useState(false);
    const creationMenu = useFloatMenu();

    const {
        openedFolders,
        folderStructure,
        getFolderStructure,
        filterByStatus,
    } = useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    useEffect(() => {
        if (showSearch) handleShowInput();
    }, [selectedProject?.id]);

    const handleShowInput = () => {
        setShowSearch(!showSearch);
        if (showSearch) {
            handleSearchTerm('');
        }
    };

    const CREATION_MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'topic' }),
                onClick: () => {
                    creationMenu.handleMenu();
                    handleTopicMenu();
                },
            },
            {
                label: intl.formatMessage({ id: 'folder' }),
                onClick: () => {
                    creationMenu.handleMenu();
                    folderMenu.handleMenu();
                },
            },
        ],
        [handleTopicMenu, creationMenu, folderMenu]
    );

    const { menus: filtersMenu } = useTopicsSidebarFilterMenu({
        closeMainMenu: filterMenu.handleMenu,
        childrenMenuClassName: styles.cgSidebarHeader__childrenMenu,
    });

    return (
        <div className={styles.cgSidebarHeader}>
            {folderMenu.showMenu && (
                <CreateFolderModal
                    handleClose={() => folderMenu.setShowMenu(false)}
                    projectId={selectedProject?.id as string}
                    onCreateFolder={getFolderStructure}
                    folderStructure={folderStructure}
                    initialExpandedFolders={openedFolders}
                />
            )}

            <div
                className={styles.cgSidebarHeader__actions}
                ref={actionsContainer}
            >
                <Search onClick={handleShowInput} />

                {!lite && (
                    <>
                        <RestrictedUI
                            entity={ENTITIES.TOPICS}
                            to={PERMISSIONS.EDIT}
                        >
                            <BasePopover
                                isOpen={creationMenu.showMenu}
                                positions={['bottom', 'left', 'right']}
                                align="start"
                                content={
                                    <BasicMenuList
                                        menus={CREATION_MENUS}
                                        className={
                                            styles.cgSidebarHeader__folderMenu
                                        }
                                        legend={intl.formatMessage({
                                            id: 'create_new',
                                        })}
                                    />
                                }
                                onClickOutside={() =>
                                    creationMenu.setShowMenu(false)
                                }
                                parentContainer={actionsContainer.current}
                            >
                                <PlusCircle onClick={creationMenu.handleMenu} />
                            </BasePopover>
                        </RestrictedUI>

                        <StarMenuIcon onClick={openSavedSearchesModal} />
                    </>
                )}

                <BasePopover
                    isOpen={filterMenu.showMenu}
                    positions={['bottom', 'left', 'right']}
                    align="start"
                    content={
                        <BasicMenuList
                            className={styles.cgSidebarHeader__filterMenu}
                            menus={filtersMenu}
                        />
                    }
                    onClickOutside={() => filterMenu.setShowMenu(false)}
                >
                    <SlidersIcon
                        className={classNames({
                            [styles['cgSidebarHeader__filterIcon--applied']]:
                                Boolean(filterByStatus),
                        })}
                        onClick={filterMenu.handleMenu}
                    />
                </BasePopover>
            </div>

            {showSearch && (
                <form
                    className={`${styles.cgSidebarHeader__search} animate__animated animate__fadeIn`}
                >
                    <TextField
                        iconBefore={Search}
                        inputProps={{
                            onChange: (e) => handleSearchTerm(e.target.value),
                            value: searchTerm,
                            placeholder: intl.formatMessage({
                                id: 'type_something',
                            }),
                            autoFocus: true,
                        }}
                    />
                    <span
                        className={styles.cgSidebarHeader__searchToggler}
                        onClick={handleShowInput}
                    >
                        {intl.formatMessage({ id: 'hide_search' })}
                    </span>
                </form>
            )}
        </div>
    );
};

export default SidebarHeader;

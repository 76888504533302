import { useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import Skeleton from 'react-loading-skeleton';
import ReportsList from './ReportsList';
import ReportsSidebarHeader from './ReportsSidebarHeader';
import useReportsStore from '../../stores/reportsStore';
import useReports from '../../hooks/useReports';

import styles from './ReportsSidebar.module.scss';

const ReportsSidebar = () => {
    const [search, setSearch] = useState('');

    const [loading, reports, handleSelectedReport] = useReportsStore(
        useShallow((state) => [
            state.loading,
            state.reports,
            state.handleSelectedReport,
        ])
    );

    const { selectedReport } = useReports();

    const filteredReports = useMemo(() => {
        if (!search) return reports;

        return reports.filter((report) =>
            report.name.toLowerCase().includes(search.toLowerCase())
        );
    }, [reports, search]);

    return (
        <div className={styles.cgReportsSidebar}>
            <ReportsSidebarHeader handleSearch={setSearch} search={search} />

            {loading && (
                <div className={styles.cgReportsSidebar__loading}>
                    <Skeleton count={10} />
                </div>
            )}

            {!loading && (
                <ReportsList
                    reports={filteredReports}
                    onSelectReport={(report) => handleSelectedReport(report.id)}
                    selectedReport={selectedReport!}
                />
            )}
        </div>
    );
};

export default ReportsSidebar;

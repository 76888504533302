import { Check } from 'react-feather';
import { useIntl } from 'react-intl';
import { BasicMenuItemInterface } from '~/components/UIElements/BasicMenuList/MenuItem';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import { EntitySortingParameters } from '~/interfaces/common';
import getSortingFieldLabel from '~/helpers/getSortingFieldLabel';

interface SidebarSortingMenuProps {
    sorting: EntitySortingParameters;
    closeMenu: () => void;
    handleSorting: (sorting: {
        field: string;
        direction: SORT_TYPE_OPTIONS;
    }) => void;
}

interface SidebarSortingMenuResults {
    menus: BasicMenuItemInterface[];
}

const useSidebarSortingMenu = ({
    closeMenu,
    sorting,
    handleSorting,
}: SidebarSortingMenuProps): SidebarSortingMenuResults => {
    const intl = useIntl();

    const createSortOption = (
        field: string,
        direction: SORT_TYPE_OPTIONS
    ): BasicMenuItemInterface => {
        const labelObject = getSortingFieldLabel({
            field,
            direction,
        });

        return {
            label:
                intl.formatMessage({ id: labelObject.translationKey }) +
                labelObject.suffixLabel,
            onClick: () => {
                closeMenu();
                handleSorting({ field, direction });
            },
            iconAfter:
                sorting.field === field && sorting.direction === direction
                    ? Check
                    : undefined,
        };
    };

    const menus: BasicMenuItemInterface[] = [
        createSortOption('created_at', SORT_TYPE_OPTIONS.DESC),
        createSortOption('created_at', SORT_TYPE_OPTIONS.ASC),
        createSortOption('name', SORT_TYPE_OPTIONS.ASC),
        createSortOption('name', SORT_TYPE_OPTIONS.DESC),
    ];

    return { menus };
};

export default useSidebarSortingMenu;

import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import showdown from 'showdown';
import formatWrongMarkdownURL from '~/helpers/formatWrongMarkdownURL';
import './ConclusionInput.scss';
import classNames from 'classnames';
import sanitizeHtmlContent from '~/modules/Reports/helpers/sanitizeHtmlContent';

interface ConclusionInputProps {
    value: string;
    placeholder: string;
    disabled?: boolean;
    onSave: (value: string) => void;
}

const converter = new showdown.Converter();

const ConclusionInput = ({
    value,
    placeholder,
    disabled,
    onSave,
}: ConclusionInputProps) => {
    const [editorHtml, setEditorHtml] = useState(value);
    const [focused, setFocused] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const handleEditorChange = (html: string) => {
        if (disabled) return;
        const sanitizedHtml = sanitizeHtmlContent(html); // avoid xss attack

        if (sanitizedHtml === value) return;

        let markdownValue = converter.makeMarkdown(sanitizedHtml);

        // remove extra breaklines
        if (markdownValue.endsWith('\n\n'))
            markdownValue = markdownValue.slice(0, -2);

        const cleanedMarkdown = formatWrongMarkdownURL(markdownValue);

        if (timer) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                onSave(cleanedMarkdown);
            }, 1500)
        );
        setEditorHtml(html);
    };

    useEffect(() => {
        setEditorHtml(value);
    }, [value]);

    // Clear the timer on component unmount
    useEffect(() => {
        return () => {
            timer && clearTimeout(timer);
        };
    }, [timer]);

    return (
        <ReactQuill
            value={editorHtml}
            onChange={handleEditorChange}
            placeholder={placeholder}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            className={classNames('cgConclusionInput', {
                cgConclusionInput__focused: focused,
                cgConclusionInput__disabled: disabled,
            })}
            autoFocus={true}
            readOnly={disabled}
        />
    );
};

export default ConclusionInput;

import { useMemo, useState } from 'react';
import { Button, TextField } from '~/components/UIElements';
import { TopicsContext } from '~/context/topics';
import { searchServices } from '~/services';
import { ProjectContext } from '~/context/project';
import { toast } from 'react-hot-toast';
import { useEntityContext, useTemporaryErrorDisplay } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { useIntl } from 'react-intl';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';
import TopicSelectorMenu from '~/modules/Topics/components/TopicSelectorMenu';
import { SAVED_SEARCH_TYPES } from '../../types/search.enum';
import { SearchInterface } from '../../types/search.interface';
import { getSavedSearchFiltersPayload } from '~/modules/Search/helpers/searchFiltersHelpers';
import { SearchPillsFilters } from '~/modules/Search/types/savedSearch.interface';
import './SaveSearchDialog.scss';

export interface SearchToSaveInterface {
    search: SearchInterface;
    topic_id?: string | null;
    name: string;
    id?: string;
    search_type: SAVED_SEARCH_TYPES;
    filters: SearchPillsFilters;
}

interface SaveSearchDialogProps {
    searchToSave: SearchToSaveInterface;
    callback?: () => void;
    isEditing?: boolean;
    title?: string;
}

const SaveSearchDialog: React.FC<SaveSearchDialogProps> = ({
    searchToSave,
    callback,
    isEditing,
    title,
}) => {
    const intl = useIntl();

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { topics, getTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState<SearchToSaveInterface>(searchToSave);

    const selectedTopic = useMemo(
        () => topics.find((t) => t.id === search.topic_id) || null,
        [topics, search.topic_id]
    );

    const { error, handleError } = useTemporaryErrorDisplay();

    const handleChange = (value: string, name: string) => {
        setSearch({ ...search, [name]: value });
    };

    const onSubmit = async () => {
        const { topic_id, name, search: searchMetadata, filters } = search;

        if (!search.name?.trim()) {
            handleError(intl.formatMessage({ id: 'required_field' }));
            return;
        }

        setLoading(true);

        const projectId = topic_id ? null : (selectedProject?.id as string);
        const searchFilters = getSavedSearchFiltersPayload(filters);

        const payload = {
            project_id: projectId,
            topic_id,
            name,
            search_type: SAVED_SEARCH_TYPES.OPERATORS,
            search: {
                ...searchMetadata,
                project_ids: projectId ? [projectId] : [],
            },
            ...searchFilters,
        };

        const [saveSearchError] = isEditing
            ? await searchServices.updateSearch(
                  payload,
                  searchToSave?.id as string
              )
            : await searchServices.saveSearch(payload);
        setLoading(false);

        if (saveSearchError)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(
            intl.formatMessage({
                id: `search_${isEditing ? 'updated' : 'saved'}_successfully`,
            })
        );

        callback?.();
        getTopics();
    };

    const onSelectTopic = (topic: TopicInterface | null) => {
        setSearch({
            ...search,
            topic_id: topic?.id || null,
        });
    };

    return (
        <div className="cg-save-search-dialog animate__animated animate__fadeIn animate__faster">
            {title && (
                <div className="cg-save-search-dialog__header">
                    <h4>{title}</h4>
                </div>
            )}

            <div className="cg-save-search-dialog__body">
                <TextField
                    label={intl.formatMessage({ id: 'query_name' })}
                    error={error as string}
                    inputProps={{
                        maxLength: 50,
                        placeholder:
                            intl.formatMessage({
                                id: 'summarize_query',
                            }) + '...',
                        onChange: (e) => handleChange(e.target.value, 'name'),
                        value: search.name,
                    }}
                />

                <TopicSelectorMenu
                    intl={intl}
                    onSelectTopic={onSelectTopic}
                    selectedTopic={selectedTopic}
                    placeholder={
                        intl.formatMessage({ id: 'search_topics' }) + '...'
                    }
                    label={intl.formatMessage({ id: 'link_to_topic' })}
                    isOptional={true}
                />

                <Button disabled={loading} variant="accent" onClick={onSubmit}>
                    {intl.formatMessage({ id: 'save' })}
                </Button>
            </div>
        </div>
    );
};

export default SaveSearchDialog;

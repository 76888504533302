import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './SearchesByProjectList.scss';
import { useEntityContext } from '~/hooks';
import { ProjectContext } from '~/context/project';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { searchServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import {
    Accordion,
    BaseModal,
    DeleteConfirmationDialog,
} from '~/components/UIElements';
import { useIntl } from 'react-intl';
import SaveSearchDialog, {
    SearchToSaveInterface,
} from '~/modules/Search/components/SaveSearchDialog';
import SaveAISearchDialog, {
    AISearchToSaveInterface,
} from '~/modules/Search/components/SaveAISearchDialog';
import Skeleton from 'react-loading-skeleton';
import { SavedSearchesContext } from '~/modules/Search/contexts/SavedSearchesContext';
import SearchRow from '~/modules/Search/components/SearchRow';
import { SavedSearchesProviderInterface } from '~/modules/Search/types/savedSearchesContext.interface';
import { SAVED_SEARCH_TYPES } from '~/modules/Search/types/search.enum';
import {
    SavedAISearchInterfaceDetailed,
    SavedSearchInterfaceDetailed,
} from '~/modules/Search/types/savedSearch.interface';
import { getRevertedSavedSearchFiltersPayload } from '~/modules/Search/helpers/searchFiltersHelpers';
import { SearchBarFiltersInterface } from '~/modules/Search/types/search.interface';

interface SearchesByProjectListProps {
    onSelectSearch: (
        search: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ) => void;
}

interface SearchesGroupedByTopicInterface {
    [key: string]:
        | SavedAISearchInterfaceDetailed[]
        | SavedSearchInterfaceDetailed[];
}

const mainClass = 'cg-searches-by-project';
const UNLINKED_SEARCHES = 'unlinked';

const SearchesByProjectList: React.FC<SearchesByProjectListProps> = ({
    onSelectSearch,
}) => {
    const intl = useIntl();

    const [loading, setLoading] = useState(false);

    const [expandedSearches, setExpandedSearches] = useState<string[]>([]);

    const [searches, setSearches] = useState<
        SavedSearchInterfaceDetailed[] | SavedAISearchInterfaceDetailed[]
    >([]);

    const [editingSearch, setEditingSearch] = useState<
        SearchToSaveInterface | AISearchToSaveInterface | null
    >(null);

    const [searchToDelete, setSearchToDelete] = useState<string | null>(null);

    useEffect(() => {
        getSearches();
    }, []);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { topics, getTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { getSavedSearches: refreshSavedSearchesContext } =
        useEntityContext<SavedSearchesProviderInterface>(SavedSearchesContext);

    const getSearches = async () => {
        setLoading(true);

        const [error, response] = await searchServices.getSavedSearches({
            tree_project_ids: selectedProject?.id,
            page_size: 200,
        });

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

            return;
        }
        let { data } = response;
        // TODO: only show searches with micro_conditions (macros are no longer available)
        data = data.filter((search) =>
            Boolean(search.search?.micro_conditions)
        );

        setSearches(data);
    };

    const searchesGroupedByTopic = useMemo(() => {
        const groupedSearches: SearchesGroupedByTopicInterface = {};

        searches.forEach((search) => {
            const topicId = search.topic_id;

            if (topicId) {
                const prev = groupedSearches[topicId] || [];
                groupedSearches[topicId] = [...prev, search];
            } else {
                const prev = groupedSearches[UNLINKED_SEARCHES] || [];
                groupedSearches[UNLINKED_SEARCHES] = [...prev, search];
            }
        });

        return groupedSearches;
    }, [searches]);

    const getTopicName = (topicId: string) => {
        const topic = topics.find((t) => t.id === topicId);
        return topic?.name ?? intl.formatMessage({ id: 'unlinked' });
    };

    const handleSearchEdit = (
        search: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ) => {
        let payload = {
            id: search.id,
            name: search.name,
            topic_id: search.topic_id,
            project_id: search.topic_id,
            search_type: search.search_type,
        };

        if (search.search_type === SAVED_SEARCH_TYPES.AI) {
            payload = {
                ...payload,
                ai_search: search.ai_search,
            };
        } else {
            payload = {
                ...payload,
                search: search.search,
            };
        }

        setEditingSearch(payload);
    };
    const onDeleteSearch = async () => {
        setLoading(true);

        const [error] = await searchServices.deleteSearch(
            searchToDelete as string
        );
        setLoading(false);

        setSearchToDelete(null);

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(
            intl.formatMessage({ id: 'search_deleted_successfully' })
        );
        getSearches();
        refreshSavedSearchesContext();

        getTopics();
    };

    const onEditSearch = () => {
        refreshSavedSearchesContext();
        getSearches();

        setEditingSearch(null);
    };

    const handleExpandedSearches = (topicId: string) => {
        if (expandedSearches.includes(topicId)) {
            setExpandedSearches((prev) => prev.filter((id) => id !== topicId));
        } else {
            setExpandedSearches((prev) => [...prev, topicId]);
        }
    };

    const getQueryFilters = (
        query: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ): SearchBarFiltersInterface => {
        const { tag_ids, document_ids, page_numbers } = query;

        const payload = getRevertedSavedSearchFiltersPayload({
            tag_ids,
            document_ids,
            page_numbers,
        });

        if (query.search_type === SAVED_SEARCH_TYPES.OPERATORS) {
            payload.case_sensitive = query.search.case_sensitive;
            payload.exact_match = query.search.exact_match;
        }

        return payload;
    };

    return (
        <div className={mainClass}>
            {searchToDelete && (
                <DeleteConfirmationDialog
                    handleClose={() => setSearchToDelete(null)}
                    title={intl.formatMessage({ id: 'delete_search' })}
                    loading={loading}
                    callback={onDeleteSearch}
                    text={intl.formatMessage({ id: 'delete_warning' })}
                    buttonText={intl.formatMessage({ id: 'delete' })}
                />
            )}

            {editingSearch && (
                <BaseModal
                    size="small"
                    handleClose={() => setEditingSearch(null)}
                    noPadding
                    noOverflow
                >
                    {editingSearch.search_type === SAVED_SEARCH_TYPES.AI ? (
                        <SaveAISearchDialog
                            searchToSave={
                                editingSearch as AISearchToSaveInterface
                            }
                            callback={onEditSearch}
                            title={intl.formatMessage({
                                id: 'favorite_query',
                            })}
                            isEditing
                        />
                    ) : (
                        <SaveSearchDialog
                            searchToSave={
                                editingSearch as SearchToSaveInterface
                            }
                            callback={onEditSearch}
                            title={intl.formatMessage({
                                id: 'favorite_query',
                            })}
                            isEditing
                        />
                    )}
                </BaseModal>
            )}

            {loading ? (
                <Skeleton
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '10px',
                    }}
                    height={30}
                    count={4}
                />
            ) : (
                <>
                    {searches.length === 0 && (
                        <div className={mainClass + '__empty'}>
                            {intl.formatMessage({
                                id: 'no_saved_searches',
                            })}
                            .
                        </div>
                    )}

                    {Object.keys(searchesGroupedByTopic).map((topicId) => (
                        <div
                            key={topicId}
                            className={mainClass + '__accordion'}
                        >
                            <Accordion
                                title={getTopicName(topicId)}
                                onExpand={() => handleExpandedSearches(topicId)}
                                expanded={expandedSearches.includes(topicId)}
                            >
                                {searchesGroupedByTopic[topicId].map(
                                    (search) => (
                                        <SearchRow
                                            key={search.id}
                                            name={
                                                search.ai_search?.prompt ||
                                                search.name
                                            }
                                            onSelectSearch={() =>
                                                onSelectSearch(search)
                                            }
                                            handleEdit={() =>
                                                handleSearchEdit(search)
                                            }
                                            handleDelete={() =>
                                                setSearchToDelete(search.id)
                                            }
                                            blocks={
                                                search.search?.micro_conditions
                                            }
                                            filters={getQueryFilters(search)}
                                        />
                                    )
                                )}
                            </Accordion>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default SearchesByProjectList;

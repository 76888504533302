import { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import { ProjectContext } from '~/context/project';
import { useEntityContext } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import {
    SavedAISearchInterfaceDetailed,
    SavedSearchInterfaceDetailed,
} from '~/modules/Search/types/savedSearch.interface';
import { SavedSearchesProviderInterface } from '~/modules/Search/types/savedSearchesContext.interface';
import { SAVED_SEARCH_TYPES } from '~/modules/Search/types/search.enum';
import { searchServices } from '~/services';

export const SavedSearchesContext =
    createContext<SavedSearchesProviderInterface | null>(null);

const SavedSearchesProvider = ({ children }: PropsWithChildren) => {
    const [loading, setLoading] = useState(false);
    const [savedSearches, setSavedSearches] = useState<
        SavedSearchInterfaceDetailed[]
    >([]);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const getSavedSearches = async () => {
        if (!selectedProject) return;

        setLoading(true);
        const [error, response] = await searchServices.getSavedSearches({
            tree_project_ids: selectedProject?.id,
            page_size: 200,
            sort_attribute: 'created_at',
            sort_order: SORT_TYPE_OPTIONS.DESC,
        });

        setLoading(false);
        if (error) return;

        setSavedSearches(response.data);
    };

    const filteredSearches = useMemo(() => {
        const queries: SavedSearchInterfaceDetailed[] = [];
        const prompts: SavedAISearchInterfaceDetailed[] = [];

        savedSearches.forEach((search) => {
            if (
                search.search_type === SAVED_SEARCH_TYPES.OPERATORS &&
                search.search.micro_conditions
            ) {
                // TODO: only show searches with micro_conditions (macros are no longer available)
                queries.push(search as SavedSearchInterfaceDetailed);
            } else {
                prompts.push(search as SavedAISearchInterfaceDetailed);
            }
        });
        return {
            queries,
            prompts,
        };
    }, [savedSearches]);

    return (
        <SavedSearchesContext.Provider
            value={{
                savedSearches: filteredSearches.queries,
                savedAISearches: filteredSearches.prompts,
                loading,
                getSavedSearches,
            }}
        >
            {children}
        </SavedSearchesContext.Provider>
    );
};

export default SavedSearchesProvider;

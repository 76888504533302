import { TopicInterfaceDetailed } from '../../types/Topic.interface';
import TopicItem from '../TopicItem';

import './TopicsList.scss';

interface TopicListInterface {
    topics: TopicInterfaceDetailed[];
    lite?: boolean;
    selectedItems: TopicInterfaceDetailed[];
    emptyMsg: string;
    onSelectTopic: (topic: TopicInterfaceDetailed) => void;
    handleSearchesList: (topic: TopicInterfaceDetailed) => void;
    onUpdateTopic: () => void;
}

const mainClass = 'cg-sidebar-topics-list';

const TopicsList: React.FC<TopicListInterface> = ({
    topics,
    lite,
    selectedItems,
    emptyMsg,
    onSelectTopic,
    handleSearchesList,
    onUpdateTopic,
}) => {
    return (
        <div
            className={
                mainClass +
                ' animate__animated animate__faster animate__fadeInLeft'
            }
        >
            {!topics.length ? (
                <span className="dg-tags-sidebar__empty">{emptyMsg}</span>
            ) : (
                <div className={mainClass + '__list'}>
                    {topics.map((topic) => (
                        <TopicItem
                            key={topic.id}
                            topic={topic}
                            isSelected={selectedItems.some(
                                (t) => t.id === topic.id
                            )}
                            lite={lite}
                            onPin={onSelectTopic}
                            handleSearchesList={handleSearchesList}
                            onUpdateTopic={onUpdateTopic}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TopicsList;

import { useMemo } from 'react';
import { BaseModal } from '~/components/UIElements';
import { SnippetsContext } from '../../contexts/SnippetsContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { SourcesContext } from '../../contexts/SourcesContext';
import { getParsedBlocks } from '~/utils/traverseBlocksFunctions';
import { PRIVATE_ROUTES } from '~/components/Router/paths';
import { useEntityContext } from '~/hooks';
import { SidebarProviderInterface } from '~/interfaces/contexts';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import { useIntl } from 'react-intl';
import SearchesByTopicList from '~/modules/Search/components/SavedSearchesModal/SearchesByTopicList';
import SearchesByProjectList from '~/modules/Search/components/SavedSearchesModal/SearchesByProjectList';
import { SidebarContext } from '~/context/sidebar';
import { AIResultsContext } from '~/modules/Search/contexts/AIResultsContext';
import { SourcesProviderInterface } from '~/modules/Search/types/sourcesContext.interface';
import useSearchStore from '~/modules/Search/stores/searchStore';
import {
    SAVED_SEARCH_TYPES,
    SEARCH_TYPES,
} from '~/modules/Search/types/search.enum';
import { useShallow } from 'zustand/react/shallow';
import { SnippetsProviderInterface } from '~/modules/Search/types/snippetsContext.interface';
import { AIResultsProviderInterface } from '~/modules/Search/types/AIResultsContext.interface';
import {
    SavedAISearchInterfaceDetailed,
    SavedSearchInterfaceDetailed,
} from '~/modules/Search/types/savedSearch.interface';
import {
    getRevertedSavedSearchFiltersPayload,
    transformSavedSearchFilters,
} from '~/modules/Search/helpers/searchFiltersHelpers';
import { ConditionsPresentation } from '~/modules/Search/types/search.interface';
import './SavedSearchesModal.scss';

interface SavedSearchesModalProps {
    handleClose: () => void;
    topic?: TopicInterfaceDetailed;
}

const SavedSearchesModal: React.FC<SavedSearchesModalProps> = ({
    topic,
    handleClose,
}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // check if the user is in the search page, in order to know if is necessary to redirect him to the search page after selecting a search
    const isSearchPage = useMemo(
        () => [PRIVATE_ROUTES.search, PRIVATE_ROUTES.root].includes(pathname),
        [pathname]
    );

    const [handleSearchBarType, handleFilters, handleSearchType] =
        useSearchStore(
            useShallow((state) => [
                state.handleSearchBarType,
                state.handleFilters,
                state.handleSearchType,
            ])
        );

    const { setBlocks } =
        useEntityContext<SnippetsProviderInterface>(SnippetsContext);

    const { handleSearch } =
        useEntityContext<SourcesProviderInterface>(SourcesContext);

    const { floatSidebarMenu } =
        useEntityContext<SidebarProviderInterface>(SidebarContext);

    const { handlePrompt, onSearch } =
        useEntityContext<AIResultsProviderInterface>(AIResultsContext);

    const onSelectManualSearch = (query: SavedSearchInterfaceDetailed) => {
        const { search, project_id, document_ids, page_numbers, tag_ids } =
            query;

        const searchBarFilters = transformSavedSearchFilters({
            document_ids,
            page_numbers,
            tag_ids,
        });

        const payload = {
            conditions: {
                micro_conditions:
                    search.micro_conditions as ConditionsPresentation,
            },
            filters: {
                exact_match: Boolean(search.exact_match),
                case_sensitive: Boolean(search.case_sensitive),
                ...searchBarFilters,
            },
            project_ids: [project_id as string],
        };

        handleFilters(payload.filters);
        handleSearchType(SEARCH_TYPES.MANUAL);
        handleSearchBarType(SEARCH_TYPES.MANUAL);

        const queryBlocks = getParsedBlocks(
            search?.micro_conditions?.expression
        );

        setBlocks(queryBlocks);
        handleSearch(false, payload);
    };

    const onSelectAISearch = (query: SavedAISearchInterfaceDetailed) => {
        const { ai_search, document_ids, tag_ids, page_numbers } = query;

        const pillFilters = getRevertedSavedSearchFiltersPayload({
            document_ids,
            tag_ids,
            page_numbers,
        });

        const searchBarFilters = {
            ...pillFilters,
            case_sensitive: false,
            exact_match: false,
        };

        handleFilters(searchBarFilters);

        handlePrompt(query.ai_search.prompt);
        onSearch({
            prompt: ai_search.prompt,
            project_id: query.project_id as string,
            filters: searchBarFilters,
        });
        handleSearchType(SEARCH_TYPES.AI);
        handleSearchBarType(SEARCH_TYPES.AI);
    };

    const handleSelectSearch = (
        query: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ) => {
        if (!isSearchPage) navigate(PRIVATE_ROUTES.search);

        if (query.search_type === SAVED_SEARCH_TYPES.OPERATORS) {
            onSelectManualSearch(query as SavedSearchInterfaceDetailed);
        } else {
            onSelectAISearch(query as SavedAISearchInterfaceDetailed);
        }

        handleClose();
        floatSidebarMenu.setShowMenu(false);
    };

    return (
        <BaseModal handleClose={handleClose} size="large" noPadding>
            {topic ? (
                <SearchesByTopicList
                    intl={intl}
                    handleClose={handleClose}
                    preselectedTopic={topic.id}
                    onSelectSearch={handleSelectSearch}
                />
            ) : (
                <SearchesByProjectList onSelectSearch={handleSelectSearch} />
            )}
        </BaseModal>
    );
};

export default SavedSearchesModal;

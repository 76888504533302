import { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { PlusCircle, Search } from 'react-feather';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { BaseFloatMenu, BasePopover, TextField } from '~/components/UIElements';
import { CreateReportDialog } from '~/modules/Reports/components';
import RestrictedUI from '~/components/RestrictedUI';
import useReports from '~/modules/Reports/hooks/useReports';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import classNames from 'classnames';

import styles from './ReportsSidebarHeader.module.scss';

const ReportsSidebarHeader = ({
    handleSearch,
    search,
}: {
    search: string;
    handleSearch: (term: string) => void;
}) => {
    const intl = useIntl();
    const [showSearch, setShowSearch] = useState(false);

    const { onCreateReport, newReportMenu } = useReports();

    const loading = useReportsStore((state) => state.loading);

    const handleShowInput = () => {
        setShowSearch(!showSearch);
        if (showSearch) {
            handleSearch('');
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        handleSearch(term);
    };

    return (
        <div className={styles.cgReportsSidebarHeader}>
            <div className={styles.cgReportsSidebarHeader__header}>
                <span className="fw-300">
                    {intl.formatMessage({ id: 'reports' })}
                </span>

                <div className={styles.cgReportsSidebarHeader__headerActions}>
                    <Search onClick={handleShowInput} />

                    <RestrictedUI
                        to={PERMISSIONS.EDIT}
                        entity={ENTITIES.REPORTS}
                    >
                        <BasePopover
                            isOpen={newReportMenu.showMenu}
                            onClickOutside={() =>
                                newReportMenu.setShowMenu(false)
                            }
                            content={
                                <BaseFloatMenu top={0} left={15}>
                                    <CreateReportDialog
                                        onCreate={onCreateReport}
                                        onClose={newReportMenu.handleMenu}
                                        loading={loading}
                                    />
                                </BaseFloatMenu>
                            }
                        >
                            <PlusCircle onClick={newReportMenu.handleMenu} />
                        </BasePopover>
                    </RestrictedUI>
                </div>
            </div>

            {showSearch && (
                <form
                    className={classNames(
                        styles.cgReportsSidebarHeader__search,
                        'animate__animated animate__fadeIn'
                    )}
                >
                    <TextField
                        iconBefore={Search}
                        inputProps={{
                            onChange: handleChange,
                            value: search,
                            placeholder: intl.formatMessage({
                                id: 'type_something',
                            }),
                            autoFocus: true,
                        }}
                    />
                    <span
                        className={styles.cgReportsSidebarHeader__hideSearch}
                        onClick={handleShowInput}
                    >
                        {intl.formatMessage({ id: 'hide_search' })}
                    </span>
                </form>
            )}
        </div>
    );
};

export default ReportsSidebarHeader;

import {
    LocalAISavedSearchInterface,
    SavedSearchFilters,
    SavedSearchInterfaceDetailed,
    SearchPillsFilters,
} from '~/modules/Search/types/savedSearch.interface';
import {
    SearchBarFiltersDTO,
    SearchBarFiltersInterface,
} from '~/modules/Search/types/search.interface';

/*
    There is a little difference between the DTO of AI searches and the manual ones. This is a patch for it.
    Page number filters should be in singular for manual but plural for AI. 
*/
export const transformSearchBarFilters = (
    filters: SearchBarFiltersInterface
) => {
    const { filter_section_page_numbers, ...rest } = filters;

    const payload: SearchBarFiltersDTO = { ...rest };

    if (filter_section_page_numbers) {
        payload.filter_section_page_number =
            filters.filter_section_page_numbers;
    }

    return payload;
};

export const transformSavedSearchFilters = (filters: SavedSearchFilters) => {
    const payload: SearchPillsFilters = {};

    if (filters.document_ids?.length) {
        payload.filter_document_ids = filters.document_ids;
    }

    if (filters.page_numbers?.length) {
        payload.filter_section_page_numbers = filters.page_numbers;
    }

    if (filters.tag_ids?.length) {
        payload.filter_tag_ids = filters.tag_ids;
    }
    return payload;
};

export const getSavedSearchFiltersPayload = (filters: SearchPillsFilters) => {
    const payload: Pick<
        SavedSearchInterfaceDetailed,
        'document_ids' | 'page_numbers' | 'tag_ids'
    > = {};

    if (!filters) return payload;

    if (filters.filter_document_ids?.length) {
        payload.document_ids = filters.filter_document_ids;
    }

    if (filters.filter_section_page_numbers?.length) {
        payload.page_numbers = filters.filter_section_page_numbers;
    }

    if (filters.filter_tag_ids?.length) {
        payload.tag_ids = filters.filter_tag_ids;
    }

    return payload;
};

export const getRevertedSavedSearchFiltersPayload = (
    filters: Pick<
        SavedSearchInterfaceDetailed,
        'document_ids' | 'page_numbers' | 'tag_ids'
    >
) => {
    const payload: SearchPillsFilters = {};

    if (filters.document_ids?.length) {
        payload.filter_document_ids = filters.document_ids;
    }

    if (filters.page_numbers?.length) {
        payload.filter_section_page_numbers = filters.page_numbers;
    }

    if (filters.tag_ids?.length) {
        payload.filter_tag_ids = filters.tag_ids;
    }

    return payload;
};

export const getAISearchFiltersPayload = (
    query: LocalAISavedSearchInterface
) => {
    const payload: SearchPillsFilters = {};

    if (query.filter_document_ids) {
        payload.filter_document_ids = query.filter_document_ids;
    }
};

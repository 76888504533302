import { timeUtils } from './times';

interface DateAccumulation {
    [key: string]: string[];
}

/**
 * Receives a stringified date / timestamp array.
 *
 * Returns a mapped object in which original dates are aggregated
 * by hourly rates.
 */
export function accumulateDates(dates: string[]) {
    const parsedStringTimestamps = dates.map((value) => ({
        originalValue: value,
        parsedValue: timeUtils.getShortMonthDateAndHour(new Date(value)),
    }));

    const dateAccumulation: DateAccumulation = {};

    for (const { originalValue, parsedValue } of parsedStringTimestamps) {
        dateAccumulation[parsedValue] ??= [];

        dateAccumulation[parsedValue].push(originalValue);
    }

    return dateAccumulation;
}

import { Button, TextField } from '~/components/UIElements';
import { X } from 'react-feather';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './CreateReportDialog.scss';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { useIntl } from 'react-intl';

const initialValues = {
    name: '',
    project: null,
};

interface CreateReportDialogProps {
    loading: boolean;
    onCreate: (name: string) => void;
    onClose: () => void;
}

const CreateReportDialog = ({
    loading,
    onCreate,
    onClose,
}: CreateReportDialogProps) => {
    const intl = useIntl();

    const validateSchema = Yup.object().shape({
        name: Yup.string().required(
            intl.formatMessage({ id: 'required_field' })
        ),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit: ({ name }) => onCreate(name),
        validateOnChange: false,
    });

    const { name } = formik.values;

    return (
        <div className="cg-create-report-dialog">
            <div>
                <h4>{intl.formatMessage({ id: 'new_report' })}</h4>
                <X
                    onClick={(e) => {
                        onClose();
                        e.stopPropagation();
                    }}
                />
            </div>

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    label={intl.formatMessage({ id: 'report_name' })}
                    error={formik.errors.name}
                    inputProps={{
                        onChange: formik.handleChange,
                        name: 'name',
                        value: name,
                        placeholder: 'eg: Economics',
                        autoFocus: true,
                    }}
                />

                <Button
                    disabled={loading}
                    type="submit"
                    onClick={() => {
                        analytics.trackGenericEvent(
                            EVENT_NAMES_ENUM.CREATE_REPORT
                        );
                    }}
                    variant="accent"
                >
                    {intl.formatMessage({ id: 'create_report' })}
                </Button>
            </form>
        </div>
    );
};

export default CreateReportDialog;

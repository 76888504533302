import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SearchBlockInterface } from '~/modules/Search/types/search.interface';
import { getParsedBlocks } from '~/utils/traverseBlocksFunctions';
import { Pill } from '~/components/UIElements';
import { handleOperatorPillColor } from '~/utils/searchPillsColorOverrides';
import { BLOCK_TYPES } from '~/modules/Search/types/search.enum';
import { ConditionsPresentation } from '../../types/search.interface';

import styles from './SearchDisplay.module.scss';
interface SearchDisplayProps {
    blocks: ConditionsPresentation;
    onClick?: (e: React.MouseEvent) => void;
}

const SearchDisplay = ({ blocks, onClick }: SearchDisplayProps) => {
    const intl = useIntl();

    const getBlock = useCallback((block: SearchBlockInterface) => {
        if (block.type === BLOCK_TYPES.text)
            return (
                <Pill key={block.id}>
                    {block.negative && (
                        <span>{intl.formatMessage({ id: 'exclude' })}:</span>
                    )}
                    {block.value}
                </Pill>
            );
        if (block.type === BLOCK_TYPES.operator) {
            const colorOverrideClass = handleOperatorPillColor(block.value);
            return (
                <Pill
                    key={block.id}
                    variant={'primary'}
                    className={colorOverrideClass ?? ''}
                >
                    {block.value}
                </Pill>
            );
        }

        return (
            <div className={styles.cgSearchDisplay__block} key={block.id}>
                {block.content?.map(getBlock)}
            </div>
        );
    }, []);

    const searchBlocks = getParsedBlocks(blocks.expression);

    return (
        <div className={styles.cgSearchDisplay} onClick={(e) => onClick?.(e)}>
            <div className={styles.cgSearchDisplay__conditions}>
                {searchBlocks.map(getBlock)}
            </div>
        </div>
    );
};

export default SearchDisplay;

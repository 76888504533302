import { create } from 'zustand';
import { fetchReports, fetchSections } from './reportsStoreActions';
import { ReportsStoreInterface } from '~/modules/Reports/types/reportsStore.interface';
import { REPORT_AI_SUMMARY_TYPES } from '~/constants/ReportAISummaryTypes.enum';

const useReportsStore = create<ReportsStoreInterface>()((set, get) => ({
    loading: false,
    loadingSections: false,
    loadingConclusion: false,
    reports: [],
    selectedReport: null,
    selectedSource: null,
    sections: [],
    AISettings: {
        summary_type: REPORT_AI_SUMMARY_TYPES.SHORT,
        include_report_section_sources: true,
        include_insight_links: true,
    },
    handleLoading: (loading) => set({ loading }),
    handleLoadingConclusion: (loadingConclusion) => set({ loadingConclusion }),
    handleLoadingSections: (loadingSections) => set({ loadingSections }),
    handleSelectedSource: (selectedSource) => set({ selectedSource }),
    handleSelectedReport: (selectedReport) => set({ selectedReport }),
    handleReports: (reports) => set({ reports }),
    handleSections: (sections) => set({ sections }),
    handleAISettings: (AISettings) => set({ AISettings }),
    fetchReports: fetchReports(set, get),
    fetchSections: fetchSections(set, get),
}));

export default useReportsStore;

import { useIntl } from 'react-intl';
import { useState } from 'react';
import { Plus } from 'react-feather';
import { Button, Spinner } from '~/components/UIElements';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { useShallow } from 'zustand/react/shallow';
import {
    InsightInterface,
    ReportInterface,
    ReportSectionInterfaceDetailed,
} from '~/interfaces/entities';
import { useEntityContext } from '~/hooks';
import { TopicsContext } from '~/context/topics';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import EditableBox from '../../EditableBox';
import RestrictedUI from '~/components/RestrictedUI';
import SourcesStack from '~/modules/Reports/components/SourcesStack';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import useReports from '~/modules/Reports/hooks/useReports';
import groupSourcesByTopic from '~/modules/Reports/helpers/groupSourcesByTopic';
import SourcesModal from '~/components/SourcesModal';
import styles from './Sections.module.scss';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';
import useReportSections from './useReportSections.hook';

interface SourceInterface {
    report: ReportInterface;
    section: ReportSectionInterfaceDetailed;
    isNew: boolean;
    topic: TopicInterface;
    commentIds: string[];
}

const Sections = ({ canEdit }: { canEdit: boolean }) => {
    const intl = useIntl();
    const { topics } = useEntityContext<TopicsProviderInterface>(TopicsContext);

    const [selectedSource, setSelectedSource] =
        useState<SourceInterface | null>(null);

    const {
        focusedSection,
        onRemoveSection,
        onUpdateSection,
        handleFocusedSection,
        handleCreateSection,
    } = useReportSections();

    const { selectedReport } = useReports();

    const [sections, loading, fetchSections] = useReportsStore(
        useShallow((state) => [
            state.sections,
            state.loading,
            state.fetchSections,
        ])
    );

    const saveChanges =
        (section: ReportSectionInterfaceDetailed) => (text: string) => {
            handleFocusedSection(null);

            if (!text) {
                onRemoveSection(section.id);
                return;
            }

            const hasChanged = section?.text !== text;

            if (!hasChanged) return;

            const payload = {
                ...section,
                text,
            };

            onUpdateSection(section.id, payload);
        };

    const handleSourceModal = (
        comments: InsightInterface[],
        isNew = false,
        section: ReportSectionInterfaceDetailed
    ) => {
        const topic = topics.find(
            (t) => t.id === comments[0]?.topic_id
        ) as TopicInterface;

        const payload = {
            report: selectedReport as ReportInterface,
            section,
            isNew,
            topic,
            commentIds: comments?.map((c) => c.id) || [],
        };

        setSelectedSource(payload);
    };

    return (
        <div className={styles.cgSections}>
            {selectedSource && (
                <SourcesModal
                    source={selectedSource}
                    intl={intl}
                    handleClose={() => setSelectedSource(null)}
                    callback={() => {
                        fetchSections();
                        setSelectedSource(null);
                    }}
                />
            )}

            {sections.map((section) => (
                <div
                    key={section.id}
                    className={styles.cgSections__inputContainer}
                >
                    <EditableBox
                        value={section.text}
                        onSave={saveChanges(section)}
                        isActive={focusedSection === section.id}
                        onFocus={() => handleFocusedSection(section.id)}
                        disabled={loading || !canEdit}
                    />
                    <SourcesStack
                        sources={groupSourcesByTopic(section, topics)}
                        onSelectSource={(comments, isNew) =>
                            handleSourceModal(comments, isNew, section)
                        }
                    />
                </div>
            ))}

            {focusedSection === 'new' && (
                <EditableBox
                    value={''}
                    onSave={handleCreateSection}
                    disabled={loading}
                    isActive
                />
            )}

            <div className={styles.cgSections__footer}>
                {loading ? (
                    <Spinner width="20" height="20" weight="2" />
                ) : (
                    <RestrictedUI
                        to={PERMISSIONS.EDIT}
                        entity={ENTITIES.REPORTS}
                    >
                        <Button
                            iconBefore={Plus}
                            variant="tertiary"
                            onClick={(e) => {
                                handleFocusedSection('new');
                                e.stopPropagation();
                            }}
                        >
                            {intl.formatMessage({ id: 'add_an_idea' })}
                        </Button>
                    </RestrictedUI>
                )}
            </div>
        </div>
    );
};

export default Sections;

import { useEffect, useMemo, useState } from 'react';
import './SearchesByTopicList.scss';
import { X } from 'react-feather';
import { IntlShape } from 'react-intl';
import SaveSearchDialog, {
    SearchToSaveInterface,
} from '~/modules/Search/components/SaveSearchDialog';
import SaveAISearchDialog, {
    AISearchToSaveInterface,
} from '~/modules/Search/components/SaveAISearchDialog';
import { useEntityContext } from '~/hooks';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import { SavedSearchesContext } from '~/modules/Search/contexts/SavedSearchesContext';
import { searchServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import Skeleton from 'react-loading-skeleton';
import {
    BaseModal,
    DeleteConfirmationDialog,
    PillSelect,
} from '~/components/UIElements';
import SearchRow from '~/modules/Search/components/SearchRow';
import { SavedSearchesProviderInterface } from '~/modules/Search/types/savedSearchesContext.interface';
import { SAVED_SEARCH_TYPES } from '~/modules/Search/types/search.enum';
import {
    SavedAISearchInterfaceDetailed,
    SavedSearchInterfaceDetailed,
} from '~/modules/Search/types/savedSearch.interface';
import { SearchBarFiltersInterface } from '~/modules/Search/types/search.interface';
import { getRevertedSavedSearchFiltersPayload } from '~/modules/Search/helpers/searchFiltersHelpers';

const mainClass = 'cg-searches-by-topic';

enum SEARCH_TABS {
    QUERIES = 'queries',
    PROMPTS = 'prompts',
}

interface SearchesByTopicListProps {
    intl: IntlShape;
    preselectedTopic: string;
    handleClose: () => void;
    onSelectSearch: (
        search: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ) => void;
}

const SearchesByTopicList: React.FC<SearchesByTopicListProps> = ({
    intl,
    preselectedTopic,
    handleClose,
    onSelectSearch,
}) => {
    const [selectedTopic, setSelectedTopic] =
        useState<string>(preselectedTopic);

    const [currentTab, setCurrentTab] = useState(SEARCH_TABS.QUERIES);

    const [loading, setLoading] = useState(false);

    const [searches, setSearches] = useState<
        SavedSearchInterfaceDetailed[] | SavedAISearchInterfaceDetailed[]
    >([]);

    const [editingSearch, setEditingSearch] = useState<
        SearchToSaveInterface | AISearchToSaveInterface | null
    >(null);

    const [searchToDelete, setSearchToDelete] = useState<string | null>(null);

    useEffect(() => {
        getSearches();
    }, [selectedTopic]);

    const { getTopics, topicOptions } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { getSavedSearches: refreshSavedSearchesContext } =
        useEntityContext<SavedSearchesProviderInterface>(SavedSearchesContext);

    const getSearches = async () => {
        setLoading(true);

        const [error, response] = await searchServices.getSavedSearches({
            topic_ids: [selectedTopic],
            page_size: 200,
        });

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

            return;
        }

        let { data } = response;
        // TODO: only show searches with micro_conditions (macros are no longer available)
        data = data.filter((search) =>
            Boolean(search.search?.micro_conditions)
        );

        setSearches(data);
    };

    const handleSearchEdit = (
        search: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ) => {
        let payload;

        if (search.search_type === SAVED_SEARCH_TYPES.AI) {
            payload = {
                name: search.name,
                topic_id: search.topic_id,
                project_id: search.topic_id,
                ai_search: search.ai_search,
                search_type: SAVED_SEARCH_TYPES.AI,
            };
        } else {
            payload = {
                name: search.name,
                topic_id: search.topic_id,
                project_id: search.topic_id,
                search: search.search,
                search_type: SAVED_SEARCH_TYPES.OPERATORS,
            };
        }

        setEditingSearch(payload);
    };

    const onDeleteSearch = async () => {
        setLoading(true);

        const [error] = await searchServices.deleteSearch(
            searchToDelete as string
        );
        setLoading(false);

        setSearchToDelete(null);

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(
            intl.formatMessage({ id: 'search_deleted_successfully' })
        );
        getSearches();
        refreshSavedSearchesContext();

        getTopics();
    };

    const onEditSearch = () => {
        refreshSavedSearchesContext();
        getSearches();
        setEditingSearch(null);
    };

    const filteredSearches = useMemo(() => {
        const queries: SavedSearchInterfaceDetailed[] = [];
        const prompts: SavedAISearchInterfaceDetailed[] = [];

        searches.forEach((search) => {
            if (search.search_type === SAVED_SEARCH_TYPES.OPERATORS) {
                queries.push(search as SavedSearchInterfaceDetailed);
            } else {
                prompts.push(search as SavedAISearchInterfaceDetailed);
            }
        });

        return {
            queries,
            prompts,
        };
    }, [searches]);

    const results =
        currentTab === SEARCH_TABS.QUERIES
            ? filteredSearches.queries
            : filteredSearches.prompts;

    const selectedTopicOption = useMemo(
        () => topicOptions.find((t) => t.value === selectedTopic),
        [selectedTopic, topicOptions]
    );

    const getQueryFilters = (
        query: SavedSearchInterfaceDetailed | SavedAISearchInterfaceDetailed
    ): SearchBarFiltersInterface => {
        const { tag_ids, document_ids, page_numbers } = query;

        const payload = getRevertedSavedSearchFiltersPayload({
            tag_ids,
            document_ids,
            page_numbers,
        });

        if (query.search_type === SAVED_SEARCH_TYPES.OPERATORS) {
            payload.case_sensitive = query.search.case_sensitive;
            payload.exact_match = query.search.exact_match;
        }

        return payload;
    };

    return (
        <div className={mainClass}>
            {searchToDelete && (
                <DeleteConfirmationDialog
                    handleClose={() => setSearchToDelete(null)}
                    title={intl.formatMessage({ id: 'delete_search' })}
                    loading={loading}
                    callback={onDeleteSearch}
                    text={intl.formatMessage({ id: 'delete_warning' })}
                    buttonText={intl.formatMessage({ id: 'delete' })}
                />
            )}

            {editingSearch && (
                <BaseModal
                    size="small"
                    handleClose={() => setEditingSearch(null)}
                    noPadding
                    noOverflow
                >
                    {editingSearch.search_type === SAVED_SEARCH_TYPES.AI ? (
                        <SaveAISearchDialog
                            searchToSave={
                                editingSearch as AISearchToSaveInterface
                            }
                            callback={onEditSearch}
                            title={intl.formatMessage({
                                id: 'favorite_query',
                            })}
                            isEditing
                        />
                    ) : (
                        <SaveSearchDialog
                            searchToSave={
                                editingSearch as SearchToSaveInterface
                            }
                            callback={onEditSearch}
                            title={intl.formatMessage({
                                id: 'favorite_query',
                            })}
                            isEditing
                        />
                    )}
                </BaseModal>
            )}

            <div className={mainClass + '__header'}>
                <div className={mainClass + '__tabs'}>
                    <span
                        onClick={() => setCurrentTab(SEARCH_TABS.QUERIES)}
                        className={`${mainClass}__${currentTab === SEARCH_TABS.QUERIES ? 'tabs-selected' : ''}`}
                    >
                        {intl.formatMessage({ id: 'queries' })}
                    </span>
                    <span
                        onClick={() => setCurrentTab(SEARCH_TABS.PROMPTS)}
                        className={`${mainClass}__${currentTab === SEARCH_TABS.PROMPTS ? 'tabs-selected' : ''}`}
                    >
                        {intl.formatMessage({ id: 'prompts' })}
                    </span>
                </div>

                <div>
                    <X onClick={handleClose} />
                </div>
            </div>

            <div className={mainClass + '__content'}>
                <div className={mainClass + '__selector'}>
                    <span>{intl.formatMessage({ id: 'linked_to' })} </span>
                    <PillSelect
                        options={topicOptions}
                        placeholder={intl.formatMessage({
                            id: 'select_topic',
                        })}
                        value={selectedTopicOption}
                        onChange={(e) => setSelectedTopic(e.value)}
                        variant="secondary"
                        disabled={loading}
                    />
                </div>

                {loading ? (
                    <Skeleton
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '10px',
                        }}
                        height={30}
                        count={4}
                    />
                ) : (
                    <>
                        {results.length === 0 && (
                            <div className={mainClass + '__empty'}>
                                {intl.formatMessage({
                                    id:
                                        currentTab === SEARCH_TABS.QUERIES
                                            ? 'no_saved_searches_were_found'
                                            : 'no_saved_prompts_were_found',
                                })}
                                .
                            </div>
                        )}

                        {results.map((search) => (
                            <SearchRow
                                key={search.id}
                                name={search.name || search.ai_search.prompt}
                                onSelectSearch={() => onSelectSearch(search)}
                                handleEdit={() => handleSearchEdit(search)}
                                handleDelete={() =>
                                    setSearchToDelete(search.id)
                                }
                                blocks={search.search?.micro_conditions}
                                filters={getQueryFilters(search)}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default SearchesByTopicList;

import {
    ReportInterface,
    ReportSectionInterfaceDetailed,
} from '~/interfaces/entities';
import getReportSourcesText from '~/modules/Reports/helpers/getReportSourcesText';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import exportStringToTxt from '~/utils/exportStringToTxt';

export const exportReportInTXT = (
    report: ReportInterface,
    sections: ReportSectionInterfaceDetailed[],
    topics: TopicInterfaceDetailed[],
    workspaceId: string
) => {
    const conclusionStr = '\n\nConclusion: \n\n' + report.conclusion + '\n\n';

    const buildSourcesLinks = (section: ReportSectionInterfaceDetailed) =>
        getReportSourcesText(section, topics, report.project_id, workspaceId)
            .map((t) => t.rawTextWithLink)
            .join('\n');

    exportStringToTxt(
        sections
            .map((s) => `- ${s.text} \n\n ${buildSourcesLinks(s)}`)
            .join('\n\n') + conclusionStr,
        `Cogny-Report-${report.name}.txt`
    );
};

import { AccumulatedColumnValues } from '~/interfaces/columnValues/ColumnFilterContext.interface';

enum DOCUMENTS_SEARCH_COLUMNS {
    DOCUMENT_NAME = 'name',
    USER_NAME = 'user_name',
    TAGS = 'tag_name',
    CREATED_AT = 'created_at',
    PAGE_COUNT = 'page_count',
}

enum SNIPPETS_SEARCH_COLUMNS {
    DOCUMENT_NAME = 'document_name',
    TAGS = 'tag_name',
    SECTION_PAGE_NUMBER = 'section_page_number',
}

/*
 * Rebuild original structure from backend for values that were joined
 */
export const documentMatchFilterKeys = (filters: AccumulatedColumnValues) => {
    const transformedDates: string[] = [];
    filters[DOCUMENTS_SEARCH_COLUMNS.CREATED_AT]?.forEach((v) => {
        transformedDates.push(...v.value.split('&'));
    });

    const transformedDocs: string[] = [];
    filters[DOCUMENTS_SEARCH_COLUMNS.DOCUMENT_NAME]?.forEach((v) => {
        transformedDocs.push(...v.value.split('&'));
    });

    const transformedTags: string[] = [];
    filters[DOCUMENTS_SEARCH_COLUMNS.TAGS]?.forEach((v) => {
        transformedTags.push(...v.value.split('&'));
    });

    return {
        post_filter_document_page_number: filters[
            DOCUMENTS_SEARCH_COLUMNS.PAGE_COUNT
        ]?.map((d) => Number(d.value)),

        post_filter_document_ids: transformedDocs.length
            ? transformedDocs
            : undefined,

        post_filter_tag_ids: transformedTags.length
            ? transformedTags
            : undefined,

        post_filter_document_created_at: transformedDates.length
            ? transformedDates
            : undefined,
    };
};

/**
 * Rebuild original structure from backend for values that were joined
 * @param {import('../../types/columnsFilterContextTypes').FilterAccumulation} filters
 * @returns
 */
export const snippetMatchFilterKeys = (filters: AccumulatedColumnValues) => {
    const transformedDocuments: string[] = [];
    filters[SNIPPETS_SEARCH_COLUMNS.DOCUMENT_NAME]?.forEach((v) => {
        transformedDocuments.push(...v.value.split('&'));
    });

    const transformedTags: string[] = [];
    filters[SNIPPETS_SEARCH_COLUMNS.TAGS]?.forEach((v) => {
        transformedTags.push(...v.value.split('&'));
    });

    return {
        post_filter_document_ids: transformedDocuments.length
            ? transformedDocuments
            : undefined,

        post_filter_tag_ids: transformedTags.length
            ? transformedTags
            : undefined,

        post_filter_section_page_number: filters[
            SNIPPETS_SEARCH_COLUMNS.SECTION_PAGE_NUMBER
        ]?.map((d) => Number(d.value)),
    };
};

import { useEffect, useState } from 'react';
import { ProjectContext } from '~/context/project';
import { useEntityContext } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { LocalAISavedSearchInterface } from '~/modules/Search/types/savedSearch.interface';

interface LatestPromptsInterface {
    latestPrompts: LocalAISavedSearchInterface[];
    saveLocalQuery: (query: LocalAISavedSearchInterface) => void;
}

const useLatestPrompts = (): LatestPromptsInterface => {
    const [latestPrompts, setLatestPrompts] = useState<
        LocalAISavedSearchInterface[]
    >([]);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    useEffect(() => {
        if (!selectedProject) return;

        const localQueries = localStorage.getItem('latestPrompts');

        const parsedQueries = JSON.parse(localQueries || '[]');

        const filteredQueries = parsedQueries.filter(
            (query: LocalAISavedSearchInterface) =>
                query.project_id === selectedProject?.id
        );

        if (localQueries) {
            setLatestPrompts(filteredQueries);
        }
    }, [selectedProject]);

    const saveLocalQuery = (query: LocalAISavedSearchInterface) => {
        const newQueries = [query, ...latestPrompts];

        // store 5 queries as maximum
        if (newQueries.length > 5) {
            newQueries.pop();
        }
        setLatestPrompts(newQueries);

        localStorage.setItem('latestPrompts', JSON.stringify(newQueries));
    };

    return { latestPrompts, saveLocalQuery };
};

export default useLatestPrompts;

/*
        the converter has a little bug that adds "<" and ">" symbols at the end and at the beginning of the markdown URL
        https://github.com/showdownjs/showdown/issues/809
        It's to remove them
*/
const formatWrongMarkdownURL = (markdown = '') => {
    const regex = /<https?:\/\/[^\s<>]+>/g;

    const replacedText = markdown.replace(regex, (match) => match.slice(1, -1));

    return replacedText;
};

export default formatWrongMarkdownURL;

import { ReportSectionInterfaceDetailed } from '~/interfaces/entities';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import groupSourcesByTopic from './groupSourcesByTopic';
import { generateInsightTopicFilteredLink } from '~/utils/generateLinks';

interface PlainSourceInterface {
    topicName: string;
    queryLink: string;
    rawText: string;
    rawTextWithLink: string;
}

const getReportSourcesText = (
    section: ReportSectionInterfaceDetailed,
    topics: TopicInterfaceDetailed[],
    projectId: string,
    workspaceId: string
) => {
    const sources = groupSourcesByTopic(section, topics);
    const topicNames = Object.keys(sources);

    if (!topicNames.length) return [];

    const texts: PlainSourceInterface[] = [];

    topicNames.forEach((topicName) => {
        const insightsIds = sources[topicName].map((s) => s.id);

        const { url: queryLink } = generateInsightTopicFilteredLink(
            workspaceId,
            projectId,
            { ids: insightsIds }
        );

        const topicSourcesCount = sources[topicName].length;

        const rawText = `${topicName} subset (${topicSourcesCount} ${topicSourcesCount > 1 ? ' rows' : ' row'})`;

        const payload = {
            topicName,
            queryLink,
            rawText,
            rawTextWithLink: `${rawText}: ${queryLink}`,
        };

        texts.push(payload);
    });

    return texts;
};

export default getReportSourcesText;

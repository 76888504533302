import { useIntl } from 'react-intl';
import { useShallow } from 'zustand/react/shallow';
import { generateReportLink } from '~/utils/generateLinks';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import useReports from '~/modules/Reports/hooks/useReports';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import {
    DeleteConfirmationDialog,
    IconButton,
    QueryLinkButton,
    Spinner,
} from '~/components/UIElements';
import ReportDownloadMenu from '~/modules/Reports/components/ReportHeader/ReportDownloadMenu';
import RestrictedUI from '~/components/RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { Trash2 } from 'react-feather';
import styles from './ReportHeader.module.scss';
import useReportDownload from '~/modules/Reports/components/ReportHeader/useReportDownload.hook';

const ReportHeader = () => {
    const intl = useIntl();

    const { selectedWorkspace } = useWorkspaces();

    const { downloadMenu, onDownloadPDF, onDownloadPPT, onDownloadRawText } =
        useReportDownload();

    const { selectedReport, onRemoveReport, deleteReportMenu } = useReports();

    const [loading, loadingSections] = useReportsStore(
        useShallow((state) => [state.loading, state.loadingSections])
    );

    const onCopyLink = () => {
        analytics.trackGenericEvent(EVENT_NAMES_ENUM.SEND_LINK_TO_REPORT);

        generateReportLink(
            selectedWorkspace?.id as string,
            selectedReport?.project_id as string,
            selectedReport?.id as string
        );
    };

    return (
        <div className={styles.cgReportHeader}>
            {deleteReportMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteReportMenu.handleMenu}
                    callback={() =>
                        onRemoveReport(selectedReport?.id as string)
                    }
                    buttonText={intl.formatMessage({ id: 'delete' })}
                    text={intl.formatMessage({
                        id: 'this_action_cannot_be_undone',
                    })}
                    title={intl.formatMessage({ id: 'delete_report' }) + '?'}
                    loading={loading}
                />
            )}

            <div className={styles.cgReportHeader__header}>
                <h4
                    title={selectedReport?.name}
                    className={styles.cgReportHeader__title}
                >
                    {selectedReport?.name}
                </h4>
                {loadingSections && <Spinner width="15" height="15" />}
            </div>

            <div className={styles.cgReportHeader__actions}>
                <QueryLinkButton
                    onClick={onCopyLink}
                    copiedText={intl.formatMessage({
                        id: 'link_copied_to_clipboard',
                    })}
                />

                <ReportDownloadMenu
                    downloadMenu={downloadMenu}
                    onDownloadPDF={onDownloadPDF}
                    onDownloadPPT={onDownloadPPT}
                    onDownloadRawText={onDownloadRawText}
                />

                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.REPORTS}>
                    <IconButton
                        variant="secondary"
                        icon={Trash2}
                        onClick={deleteReportMenu.handleMenu}
                        disabled={loading || loadingSections}
                    />
                </RestrictedUI>
            </div>
        </div>
    );
};

export default ReportHeader;

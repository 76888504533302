export enum SIDEBAR_SORTING_LABELS {
    NEWEST_FIRST = 'newest_first',
    OLDEST_FIRST = 'oldest_first',
    ALPHABETIC = 'alphabetic',
}

export enum SIDEBAR_SORTING_FIELDS {
    NAME = 'name',
    CREATED_AT = 'created_at',
}

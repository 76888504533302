import { PropsWithChildren, createContext, useState } from 'react';
import { SIDEBAR_VIEWS } from '~/constants/sidebarViews';
import { useFloatMenu } from '~/hooks';
import { SidebarProviderInterface } from '~/interfaces/contexts';

export const SidebarContext = createContext<SidebarProviderInterface | null>(
    null
);

SidebarContext.displayName = 'SidebarContext';

const SidebarProvider = ({ children }: PropsWithChildren) => {
    const floatSidebarMenu = useFloatMenu();

    const [view, setView] = useState<SIDEBAR_VIEWS>(SIDEBAR_VIEWS.TOPICS);

    return (
        <SidebarContext.Provider
            value={{
                view,
                handleView: setView,
                floatSidebarMenu,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export default SidebarProvider;

import { useContext, useMemo } from 'react';
import styles from './ReportWriter.module.scss';
import Showdown from 'showdown';
import { useIntl } from 'react-intl';
import ConclusionHeader from '~/modules/Reports/components/ReportWriter/ConclusionHeader';
import Sections from '~/modules/Reports/components/ReportWriter/Sections';
import useReports from '~/modules/Reports/hooks/useReports';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import PermissionContext from '~/context/permissions/PermissionContext';
import formatWrongMarkdownURL from '~/helpers/formatWrongMarkdownURL';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import ConclusionInput from './ConclusionInput';

const converter = new Showdown.Converter();

const ReportWriter = () => {
    const intl = useIntl();

    const { isAllowedTo } = useContext(PermissionContext);

    const { selectedReport, onSaveConclusion } = useReports();

    const loading = useReportsStore((state) => state.loading);
    const loadingConclusion = useReportsStore(
        (state) => state.loadingConclusion
    );

    const conclusionInputValue = useMemo(() => {
        const formattedConclusion = converter
            .makeHtml(selectedReport?.conclusion as string)
            .replace(/\s+(?=<)/g, ''); // remove blank spaces inside html tags

        const parsed = formatWrongMarkdownURL(formattedConclusion);

        return parsed;
    }, [selectedReport]);

    const canEdit = isAllowedTo(ENTITIES.REPORTS, PERMISSIONS.EDIT);

    return (
        <div className={styles.cgReportWriter}>
            <Sections canEdit={canEdit} />

            <div className={styles.cgReportWriter__conclusion}>
                <ConclusionHeader />

                {!loading && (
                    <ConclusionInput
                        value={conclusionInputValue}
                        disabled={loadingConclusion || loading || !canEdit}
                        onSave={onSaveConclusion}
                        placeholder={
                            intl.formatMessage({ id: 'write_summary_ideas' }) +
                            '...'
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ReportWriter;

import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useShallow } from 'zustand/react/shallow';
import { ProjectContext } from '~/context/project';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { ReportInterface } from '~/interfaces/entities';
import { reportServices } from '~/services';
import { getErrorCode } from '~/utils/getErrorCode';
import useReportsStore from '~/modules/Reports/stores/reportsStore';

interface ReportsHookInterface {
    selectedReport: ReportInterface | null;
    deleteReportMenu: FloatMenuInterface;
    newReportMenu: FloatMenuInterface;
    generateConclusion: () => void;
    onSaveConclusion: (text: string) => void;
    onCreateReport: (reportName: string) => void;
    onRemoveReport: (reportId: string) => void;
    resetMainStates: () => void;
}

const useReports = (): ReportsHookInterface => {
    const intl = useIntl();
    const newReportMenu = useFloatMenu();

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const deleteReportMenu = useFloatMenu();

    const [
        selectedReportId,
        reports,
        AISettings,
        fetchReports,
        handleLoading,
        handleReports,
        handleLoadingSections,
        handleSections,
        handleSelectedReport,
        handleLoadingConclusion,
    ] = useReportsStore(
        useShallow((state) => [
            state.selectedReport,
            state.reports,
            state.AISettings,
            state.fetchReports,
            state.handleLoading,
            state.handleReports,
            state.handleLoadingSections,
            state.handleSections,
            state.handleSelectedReport,
            state.handleLoadingConclusion,
        ])
    );

    const selectedReport = useMemo(() => {
        if (!reports.length) return null;
        return reports.find((r) => r.id === selectedReportId) || reports[0];
    }, [reports, selectedReportId]);

    const onCreateReport = async (reportName: string) => {
        handleLoading(true);
        const projectId = selectedProject?.id as string;

        const [error, response] = await reportServices.createReport(
            reportName,
            projectId
        );

        handleLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }
        handleSelectedReport(response.data.id);
        fetchReports(projectId);
        newReportMenu.setShowMenu(false);
    };

    const onRemoveReport = async (reportId: string) => {
        handleLoading(true);

        const projectId = selectedProject?.id as string;

        const [error, response] = await reportServices.deleteReport(reportId);

        handleLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'report_removed_successfully' })
        );

        deleteReportMenu.handleMenu();
        fetchReports(projectId);
    };

    const generateConclusion = async () => {
        handleLoadingSections(true);
        handleLoadingConclusion(true);

        const [error, response] = await reportServices.generateConclusionWithAI(
            selectedReport?.id as string,
            AISettings
        );

        handleLoadingSections(false);
        handleLoadingConclusion(false);

        if (error) {
            toast.error(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        const { data } = response;

        updateReportsArray(data);
    };

    const onSaveConclusion = async (text: string) => {
        handleLoadingConclusion(true);

        const payload = {
            ...(selectedReport as ReportInterface),
            conclusion: text,
        };

        const [error, response] = await reportServices.updateReport(
            selectedReport?.id as string,
            payload
        );

        handleLoadingConclusion(false);

        if (error) {
            toast.error(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        const { data } = response;
        updateReportsArray(data);
    };

    const updateReportsArray = (updatedReport: ReportInterface) => {
        const repIdex = reports.findIndex(
            (rep) => rep.id === selectedReport?.id
        );
        const newReports = [...reports];
        newReports[repIdex] = updatedReport;

        handleReports(newReports);
    };

    const resetMainStates = () => {
        handleReports([]);
        handleSections([]);
        handleSelectedReport(null);
    };

    return {
        selectedReport,
        deleteReportMenu,
        newReportMenu,
        generateConclusion,
        onSaveConclusion,
        onCreateReport,
        onRemoveReport,
        resetMainStates,
    };
};

export default useReports;

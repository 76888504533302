import {
    PropsWithChildren,
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { ProjectContext } from '~/context/project';
import { useEntityContext, useTable } from '~/hooks';
import uselatestPrompts from '~/hooks/useLatestPrompts';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { searchServices } from '~/services';
import { getErrorCode } from '~/utils/getErrorCode';
import { AIResultsProviderInterface } from '../types/AIResultsContext.interface';
import { SnippetMatchesInterface } from '../types/snippetsContext.interface';
import { AIParagraphSection } from '~/modules/Search/types/search.interface';
import { LocalAISavedSearchInterface } from '~/modules/Search/types/savedSearch.interface';
import useSearchStore from '~/modules/Search/stores/searchStore';

export const AIResultsContext =
    createContext<AIResultsProviderInterface | null>(null);

const AIResultsProvider = ({ children }: PropsWithChildren) => {
    const intl = useIntl();

    const [prompt, setPrompt] = useState<string>('');

    const [loading, setLoading] = useState(false);
    const [snippets, setSnippets] = useState<AIParagraphSection[]>([]);
    const [paginatedSnippets, setPaginatedSnippets] = useState<
        AIParagraphSection[]
    >([]);

    // Documents related to each snippet
    const [totalDocumentsIds, setTotalDocumentsIds] = useState<string[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const matches: SnippetMatchesInterface = useMemo(() => {
        return {
            coincidences: snippets.length,
            documents: totalDocumentsIds.length,
        };
    }, [snippets, totalDocumentsIds]);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { pageSize, pageNumber, selectedItems, onPageChange, onSelectItem } =
        useTable({
            defaultPageSize: 10,
            defaultPageNumber: 0,
            multipleSelect: true,
        });

    const { saveLocalQuery, latestPrompts } = uselatestPrompts();

    const filters = useSearchStore((state) => state.filters);

    const onSearch = async (savedQuery?: LocalAISavedSearchInterface) => {
        const projectId = selectedProject?.id as string;
        const queryPrompt = savedQuery?.prompt || prompt;
        const searchBarFilters = savedQuery ? savedQuery.filters : filters;

        if (!savedQuery) {
            saveLocalQuery({
                prompt: queryPrompt,
                project_id: projectId,
                filters: searchBarFilters,
            });
        }

        setLoading(true);

        const [error, response] = await searchServices.searchAI({
            prompt: queryPrompt,
            project_ids: [projectId],
            ...searchBarFilters,
        });

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        if (!selectedAll) {
            // reset selected items
            onSelectItem('');
        }

        const { data } = response;

        const unpagedDocuments = Object.values(
            data.post_filtered_document_names
        ).flat();

        setTotalDocumentsIds(unpagedDocuments);

        setSnippets(data.sections);
        setPaginatedSnippets(data.sections.slice(0, pageSize));

        if (pageNumber !== 0) onPageChange(0);
    };

    // PAGINATION HANDLER
    useEffect(() => {
        const totalPages = Math.ceil(snippets.length / pageSize);

        if (pageNumber === 0 || pageNumber > totalPages) return;

        // get only the snippets that are in the current page depending also on the page size
        const start = pageNumber * pageSize;
        const end = start + pageSize;
        const slice = snippets.slice(start, end);

        //simulate loading
        setLoading(true);
        setPaginatedSnippets([...paginatedSnippets, ...slice]);

        if (selectedAll) {
            onSelectItem([
                ...selectedItems,
                ...slice.map((snippet) => snippet.es_section_id),
            ]);
        }
        setTimeout(() => {
            setLoading(false);
        }, 400);
    }, [pageNumber, snippets]);

    return (
        <AIResultsContext.Provider
            value={{
                loading,
                handleLoading: setLoading,
                prompt,
                handlePrompt: setPrompt,
                latestPrompts,
                paging: {
                    selectedItems,
                    selectedAll,
                    onPageChange,
                    setSelectedAll,
                    onSelectItem,
                    pageNumber,
                },
                totalDocumentsIds,
                matches,
                snippets: paginatedSnippets,
                nonPaginatedSnippets: snippets,
                onSearch,
            }}
        >
            {children}
        </AIResultsContext.Provider>
    );
};

export default AIResultsProvider;

import { useEffect, useState } from 'react';
import { ProjectContext } from '~/context/project';
import { useEntityContext } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { LocalSavedSearchInterface } from '~/modules/Search/types/savedSearch.interface';

interface LatestQueriesInterface {
    latestQueries: LocalSavedSearchInterface[];
    saveLocalQuery: (query: LocalSavedSearchInterface) => void;
}

const useLatestQueries = (): LatestQueriesInterface => {
    const [latestQueries, setLatestQueries] = useState<
        LocalSavedSearchInterface[]
    >([]);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    useEffect(() => {
        if (!selectedProject) return;
        const localQueries = localStorage.getItem('latestQueries');

        const parsedQueries = JSON.parse(localQueries || '[]');

        const filteredQueries = parsedQueries.filter(
            (query: LocalSavedSearchInterface) =>
                query.project_id === selectedProject?.id
        );

        if (localQueries) {
            setLatestQueries(filteredQueries);
        }
    }, [selectedProject]);

    const saveLocalQuery = (query: LocalSavedSearchInterface) => {
        if (!query.micro_conditions) return;

        query.project_id = selectedProject?.id as string;
        const newQueries = [query, ...latestQueries];

        // store 5 queries as maximum
        if (newQueries.length > 5) {
            newQueries.pop();
        }
        setLatestQueries(newQueries);
        localStorage.setItem('latestQueries', JSON.stringify(newQueries));
    };

    return { latestQueries, saveLocalQuery };
};

export default useLatestQueries;

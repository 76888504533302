import { File, Plus } from 'react-feather';
import { useIntl } from 'react-intl';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { ProjectContext } from '~/context/project';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { BaseModal, BasicFieldForm, Button } from '~/components/UIElements';
import RestrictedUI from '~/components/RestrictedUI';
import useReports from '~/modules/Reports/hooks/useReports';
import useReportsStore from '~/modules/Reports/stores/reportsStore';

import styles from './ReportsPlaceholder.module.scss';

const ReportsPlaceholder = () => {
    const intl = useIntl();
    const newReportMenu = useFloatMenu();

    const { hasProjects } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const loading = useReportsStore((state) => state.loading);

    const { onCreateReport } = useReports();

    return (
        <div className={styles.cgReportsPlaceholder}>
            {newReportMenu.showMenu && (
                <BaseModal
                    handleClose={newReportMenu.handleMenu}
                    size={'small'}
                >
                    <BasicFieldForm
                        title={intl.formatMessage({ id: 'new_report' })}
                        confirmText={intl.formatMessage({ id: 'create' })}
                        loading={loading}
                        onSubmit={(reportName) => onCreateReport(reportName)}
                        placeholder={intl.formatMessage({
                            id: 'report_name',
                        })}
                    />
                </BaseModal>
            )}

            <File />
            <p className={styles.cgReportsPlaceholder__label}>
                {intl.formatMessage({
                    id: hasProjects
                        ? 'no_reports_in_project'
                        : 'no_projects_created_yet',
                })}
            </p>
            {hasProjects && (
                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.REPORTS}>
                    <Button
                        iconBefore={Plus}
                        variant="secondary"
                        onClick={(e) => {
                            newReportMenu.handleMenu();
                            e.stopPropagation();
                        }}
                    >
                        {intl.formatMessage({ id: 'create_report' })}
                    </Button>
                </RestrictedUI>
            )}
        </div>
    );
};

export default ReportsPlaceholder;

import { useState, useEffect, useRef } from 'react';
import './index.scss';
import { BaseModal } from '../UIElements';
import { Tag } from 'react-feather';
import { TopicsContext } from '~/context/topics';
import { InsightsTable } from '~/components/Insights';
import {
    useTable,
    useColumnsFilterContext,
    useFlexTableHeight,
    useEntityContext,
} from '~/hooks';
import { commentsServices } from '~/services';
import parseColumnValues from '~/utils/parseColumnValues';
import FIXED_COLUMN_NAMES from '../../constants/insightTableColumns';
import { ProjectContext } from '../../context/project';
import { useDynamicInsightColumns, useResizableBox } from '../../hooks';
import RestrictedUI from '../RestrictedUI';
import { PERMISSIONS } from '../../constants/memberPermissions';
import { ENTITIES } from '../../constants/entities';
import {
    buildSortingParameters,
    extractFilters,
} from '../../helpers/insightsDataHandlers';
import SourcesHeader from './SourcesHeader';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import TopicsSidebar from '~/modules/Topics/components/TopicsSidebar';
import { useIntl } from 'react-intl';
import getRawColumnValues from '~/helpers/getRawColumnValues';
import {
    DynamicPropertyInterfaceDetailed,
    InsightInterfaceDetailed,
} from '~/interfaces/entities';

const SourcesModal = ({ handleClose, source, callback }) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [insights, setInsights] = useState<InsightInterfaceDetailed[]>([]);

    const isFirstRender = useRef(true);

    const { getTopics, filledFolderTree } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    // this state stores the columns metadata (name, type, position, id) and other properties
    const [columnsMetadata, setColumnsMetadata] = useState<
        DynamicPropertyInterfaceDetailed[]
    >([]);

    // filters by column
    const {
        columnFilters,
        handleColumnValues,
        sortParams,
        filterParams,
        resetFilterColumn,
        onApplyFilter,
        columnValues,
    } = useColumnsFilterContext(columnsMetadata); //custom hook

    const {
        pageNumber,
        pageSize,
        totalPages,
        selectedItems,
        totalItems,
        onSelectItem,
        setTotalItems,
        onPageChange,
        setSelectedItems,
    } = useTable({
        multipleSelect: true,
        defaultPageSize: 10,
    });

    const { sidebarWidth, startResizing, boxRef } = useResizableBox({});

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender.current = false;
            return;
        }

        getColumnValues();

        pageNumber === 0 ? getInsights() : onPageChange(0);
    }, [columnFilters]);

    // for infinite scroll
    useEffect(() => {
        if (pageNumber === 0) {
            getInsights();
            return;
        }

        getMoreResults();
    }, [pageNumber]);

    useEffect(() => {
        if (!selectedProject) return;
        getTableColumns();
    }, [selectedProject?.id]);

    const getTableColumns = async () => {
        setLoading(true);
        const [error, response] = await commentsServices.getCommentColumns(
            selectedProject?.id as string
        );
        setLoading(false);

        if (error) return;

        const { data } = response;
        setColumnsMetadata(data);
        return data;
    };

    //------- DYNAMIC TABLE STARTS ----------

    const { tableColumns } = useDynamicInsightColumns({
        handleUpdateCell: setInsights,
        selectedProjectId: selectedProject?.id as string,
        setLoading,
        FIXED_COLUMN_NAMES,
        results: insights,
        allowCreation: false,
        filtering: {
            columnValues,
            resetFilterColumn,
            sortParams,
            columnFilters,
            onApplyFilter,
        },
        folderStructure: filledFolderTree,
        allowEdit: false,
        columnsMetadata,
        setColumnsMetadata,
        getTableColumns,
    });

    //------- DYNAMIC TABLE ENDS ----------

    const getMoreResults = async () => {
        setLoading(true);

        const filtersPayload = extractFilters(
            filterParams,
            columnsMetadata,
            columnValues
        );

        const params = {
            ...filtersPayload,
            page: pageNumber,
            page_size: pageSize,
            project_ids: selectedProject?.id,
            ...buildSortingParameters(sortParams),
        };

        const [error, response] = await commentsServices.getComments(params);

        if (error || !response.data.length) {
            setLoading(false);
            return;
        }

        const modifiedData = await commentsServices.appendImagesToInsights(
            response.data
        );

        setLoading(false);
        setInsights([...insights, ...modifiedData]);
    };

    const getInsights = async () => {
        const initialColumnValues = Object.keys(columnValues).length
            ? columnValues
            : await getColumnValues();

        const initialTableColumns = columnsMetadata.length
            ? columnsMetadata
            : await getTableColumns();

        const filtersPayload = extractFilters(
            filterParams,
            initialTableColumns,
            initialColumnValues
        );

        const options = {
            ...filtersPayload,
            project_ids: selectedProject?.id,
            page: pageNumber,
            page_size: pageSize,
            ...buildSortingParameters(sortParams),
        };

        setLoading(true);

        const [error, response] = await commentsServices.getComments(options);

        if (error) {
            setLoading(false);
            return;
        }

        const { data, paging } = response;
        const modifiedData =
            await commentsServices.appendImagesToInsights(data);

        setLoading(false);
        setInsights(modifiedData);
        setTotalItems(paging.total);

        // if selected topic is the same as the source, then select the items
        if (!source.isNew) setSelectedItems(source.commentIds);
    };

    const getColumnValues = async () => {
        const [error, response] = await commentsServices.getColumnValues({
            ...extractFilters(filterParams, columnsMetadata, columnValues),
            project_ids: selectedProject?.id,
        });

        if (!error) {
            const { data } = response;
            handleColumnValues(parseColumnValues(data));
            return data;
        }
    };

    const tableHeight = useFlexTableHeight({
        containerClassName: 'cg-sources-table',
        offset: 40 + (selectedItems.length ? 40 : 0),
    });

    const nonPaginatedIds = getRawColumnValues(columnValues, 'id');

    return (
        <BaseModal
            handleClose={handleClose}
            size="xlarge"
            fromBottom
            noPadding
            noOverflow
        >
            <div className="cg-sources-modal">
                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.REPORTS}>
                    <SourcesHeader
                        source={source}
                        loading={loading}
                        selectedItems={selectedItems}
                        nonPaginatedIds={nonPaginatedIds}
                        onDeleteSource={callback}
                        onAddSource={callback}
                        handleLoading={setLoading}
                    />
                </RestrictedUI>
                <div className="cg-sources-modal__wrapper">
                    <div
                        ref={boxRef}
                        onMouseDown={(e) => e.preventDefault()}
                        className="cg-sources-modal__sidebar"
                        style={{ width: sidebarWidth }}
                    >
                        <TopicsSidebar lite />
                        <div
                            className={'cg-sources-modal__sidebar__resizer'}
                            onMouseDown={startResizing}
                        >
                            <span>ll</span>
                        </div>
                    </div>

                    <div className="cg-sources-table">
                        {!insights.length && loading ? (
                            <div className="cg-sources-table__empty">
                                <Tag />
                                <p>
                                    {intl.formatMessage({
                                        id: 'no_insights_created_yet',
                                    })}
                                    .
                                </p>
                            </div>
                        ) : null}

                        {insights.length ? (
                            <InsightsTable
                                insights={insights}
                                columns={tableColumns}
                                paging={{
                                    onSelectItem,
                                    setSelectedItems,
                                    selectedItems,
                                    totalPages,
                                    pageNumber,
                                    totalItems,
                                }}
                                onScrollTouchesBottom={() =>
                                    onPageChange(pageNumber + 1)
                                }
                                loading={loading}
                                setLoading={setLoading}
                                onUpdateCallback={getTopics}
                                lite
                                tableHeight={tableHeight}
                                nonPaginatedIds={nonPaginatedIds}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};

export default SourcesModal;

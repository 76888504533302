import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { RadioButtonGroup, CheckboxField } from '~/components/UIElements';
import { REPORT_AI_SUMMARY_TYPES } from '~/constants/ReportAISummaryTypes.enum';
import { ConclusionSettingsInterface } from '~/modules/Reports/types/reportsStore.interface';

import styles from './SettingsMenu.module.scss';

interface SettingsMenuProps {
    settings: ConclusionSettingsInterface;
    onChange: (key: string, value: string | boolean) => void;
}

const SettingsMenu = ({ settings, onChange }: SettingsMenuProps) => {
    const intl = useIntl();

    const options = [
        {
            label: intl.formatMessage({ id: 'concise' }),
            name: REPORT_AI_SUMMARY_TYPES.SHORT,
        },
        {
            label: intl.formatMessage({ id: 'expansive' }),
            name: REPORT_AI_SUMMARY_TYPES.LONG,
        },
    ];

    return (
        <div
            className={
                styles.cgSettingsMenu +
                ' animate__animated animate__fadeIn animate__faster'
            }
        >
            <div>
                <RadioButtonGroup
                    label={intl.formatMessage({ id: 'form_of_expression' })}
                    options={options}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChange('summary_type', e.target.name)
                    }
                    checkedOption={settings.summary_type}
                />
            </div>

            <div className={styles.cgSettingsMenu__checkboxes}>
                <label> {intl.formatMessage({ id: 'source_quoting' })} </label>
                <CheckboxField
                    label={intl.formatMessage({ id: 'mention_sources' })}
                    id="mention_sources"
                    inputProps={{
                        checked: settings.include_report_section_sources,
                        onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            onChange(
                                'include_report_section_sources',
                                e.target.checked
                            ),
                    }}
                />
                <CheckboxField
                    label={intl.formatMessage({
                        id: 'include_links_to_sources',
                    })}
                    id="link_to_sources"
                    inputProps={{
                        checked: settings.include_insight_links,
                        onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            onChange('include_insight_links', e.target.checked),
                    }}
                />
            </div>
        </div>
    );
};

export default SettingsMenu;

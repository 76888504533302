import { create } from 'zustand';
import { fetchFiles, resetFilters } from './searchStoreActions';
import { SearchStoreInterface } from '../types/searchStore.interface';
import { SEARCH_TYPES } from '~/modules/Search/types/search.enum';
import { SearchBarFiltersInterface } from '~/modules/Search/types/search.interface';

const useSearchStore = create<SearchStoreInterface>()((set) => ({
    filters: {
        exact_match: false,
        case_sensitive: false,
    },
    handleFilters: (newFilters: SearchBarFiltersInterface) => {
        set({ filters: newFilters });
    },
    resetFilters: resetFilters(set),

    showSyntaxError: false,
    handleSyntaxError: (error: boolean) => set({ showSyntaxError: error }),

    showAIDisclaimer: false,
    handleAIDisclaimer: (show: boolean) => set({ showAIDisclaimer: show }),

    // for filtering
    files: [],
    fetchFiles: fetchFiles(set),

    searchType: SEARCH_TYPES.MANUAL,
    handleSearchType: (type: SEARCH_TYPES) => {
        set({ searchType: type });
    },

    searchBarType: SEARCH_TYPES.AI,
    handleSearchBarType: (type: SEARCH_TYPES) => {
        set({ searchBarType: type });
    },
}));

export default useSearchStore;

import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReportInterface } from '~/interfaces/entities';
import ReportItem from '../ReportItem';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import UpdateReportDialog from '../../UpdateReportDialog';
import styles from './ReportsList.module.scss';

interface ReportsListProps {
    reports: ReportInterface[];
    selectedReport: ReportInterface;
    onSelectReport: (report: ReportInterface) => void;
}

const ReportsList = ({
    reports,
    selectedReport,
    onSelectReport,
}: ReportsListProps) => {
    const intl = useIntl();

    const [reportToEdit, setReportToEdit] = useState<ReportInterface | null>(
        null
    );

    const fetchReports = useReportsStore((state) => state.fetchReports);

    return (
        <div className={styles.cgReportsList}>
            {!reports.length && (
                <div className={styles.cgReportsList__empty}>
                    {intl.formatMessage({ id: 'no_reports_found' })}
                </div>
            )}

            {reportToEdit && (
                <UpdateReportDialog
                    reportToEdit={reportToEdit}
                    handleClose={() => setReportToEdit(null)}
                    callback={() => {
                        setReportToEdit(null);
                        fetchReports(reportToEdit.project_id);
                    }}
                />
            )}

            {reports.map((report) => (
                <ReportItem
                    key={report.id}
                    report={report}
                    isSelected={selectedReport?.id === report.id}
                    onSelect={() => onSelectReport(report)}
                    handleReportToEdit={() => setReportToEdit(report)}
                    deleteCallback={() => fetchReports(report.project_id)}
                />
            ))}
        </div>
    );
};

export default ReportsList;

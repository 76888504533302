import { useMemo, useState } from 'react';
import './TopicsSidebar.scss';
import { TopicsContext } from '~/context/topics';
import { BaseModal } from '~/components/UIElements';
import Skeleton from 'react-loading-skeleton';
import { useEntityContext, useFloatMenu } from '~/hooks';
import TopicFolderTree from '~/modules/Topics/components/TopicFolderTree';
import TopicItem from '../TopicItem';
import {
    rootCopyTreeUpdatingSingleNode,
    searchFolders,
} from '~/utils/traverseFolderTreeFunctions';
import { foldersServices } from '~/services';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { TopicInterfaceDetailed } from '../../types/Topic.interface';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { ProjectContext } from '~/context/project';
import useTopic from '~/modules/Topics/components/useTopic.hook';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import TopicsList from '../TopicsList';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import CreateUpdateTopicForm from '../CreateUpdateTopicForm';
import { useIntl } from 'react-intl';
import { FolderTreeInterface } from '~/interfaces/entities';
import SidebarHeader from '~/modules/Topics/components/TopicsSidebar/SidebarHeader';
import SavedSearchesModal from '~/modules/Search/components/SavedSearchesModal';

enum TopicsSidebarRenderFormats {
    FLAT_LIST = 'FLAT_LIST',
    TREE = 'TREE',
}

// lite:  will render a basic topic list, without some features like create topic, update topic, etc.
const TopicsSidebar = ({ lite }: { lite?: boolean }) => {
    const intl = useIntl();

    const {
        loading,
        pinedTopic,
        filledFolderTree,
        openedFolders,
        folderStructure,
        topics,
        handlePinedTopic,
        getTopics,
        getFolderStructure,
        openFolder,
        setFolderStructure,
        setLoading,
    } = useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [searchTerm, setSearchTerm] = useState('');

    // This state manage the modal to show the saved searches. They can be displayed by topic or by project
    const [savedSearches, setSavedSearches] = useState<{
        topic?: TopicInterfaceDetailed;
    } | null>(null);

    const topicMenu = useFloatMenu();
    const { updateTopic } = useTopic({ isEditing: true });

    const updateTopicInTree = ({
        id,
        folderId,
    }: {
        id: string;
        folderId: string;
    }) => {
        updateTopic({ id, folder_id: folderId });
    };

    const onUpdateFolder = async (newFolder: FolderTreeInterface) => {
        setLoading(true);

        const [error, response] = await foldersServices.updateFolder(
            newFolder.id,
            {
                name: newFolder.name,
            }
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'folder_updated_successfully' })
        );

        const proof: FolderTreeInterface[] = rootCopyTreeUpdatingSingleNode(
            newFolder.id,
            newFolder,
            folderStructure
        );

        setFolderStructure(proof);
    };

    const onUpdateTopic = () => {
        getTopics();
        topicMenu.setShowMenu(false);
    };

    const onDeleteFolder = async (folderId: string) => {
        setLoading(true);
        const [error, response] = await foldersServices.deleteFolder(folderId);

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'folder_deleted_successfully' })
        );

        getTopics();
    };

    const sidebarRenderFormat: TopicsSidebarRenderFormats = useMemo(() => {
        const foldersWithoutUncategorized = folderStructure.filter(
            (f) => f.id !== UNCATEGORIZED_FOLDER_NAME
        );

        return foldersWithoutUncategorized.length
            ? TopicsSidebarRenderFormats.TREE
            : TopicsSidebarRenderFormats.FLAT_LIST;
    }, [folderStructure]);

    // filtered topics when a Flat list is displayed
    const filteredTopics = useMemo(() => {
        if (
            !topics ||
            !searchTerm.trim() ||
            sidebarRenderFormat !== TopicsSidebarRenderFormats.FLAT_LIST
        )
            return topics;

        return topics.filter((t) =>
            t.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, sidebarRenderFormat, topics]);

    // filtered folders when the Tree is displayed
    const filteredFolders = useMemo(() => {
        if (
            !filledFolderTree ||
            sidebarRenderFormat !== TopicsSidebarRenderFormats.TREE
        )
            return [];

        const newStructure = [...filledFolderTree];

        if (!searchTerm.trim()) return newStructure;

        const filtered = searchFolders(newStructure, searchTerm, true);
        return filtered;
    }, [searchTerm, filledFolderTree]);

    const onCreateSubfolder = async (
        folderName: string,
        parentFolderId: string
    ) => {
        setLoading(true);

        const [error, response] = await foldersServices.createFolder({
            name: folderName,
            project_id: selectedProject?.id as string,
            parent_id: parentFolderId,
        });

        setLoading(false);
        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'folder_created_successfully' })
        );

        getFolderStructure();
    };

    return (
        <div className="dg-topics-sidebar">
            {topicMenu.showMenu && (
                <BaseModal
                    handleClose={() => topicMenu.setShowMenu(false)}
                    showCloseIcon={false}
                >
                    <CreateUpdateTopicForm callback={onUpdateTopic} />
                </BaseModal>
            )}

            {/* LIST OF SAVED SEARCHES */}
            {savedSearches && (
                <SavedSearchesModal
                    topic={savedSearches.topic}
                    handleClose={() => setSavedSearches(null)}
                />
            )}

            <SidebarHeader
                handleSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                lite={Boolean(lite)}
                handleTopicMenu={topicMenu.handleMenu}
                openSavedSearchesModal={() => setSavedSearches({})}
            />

            <div className="dg-topics-sidebar__container">
                {loading && !openedFolders.length ? (
                    <div className="dg-topics-sidebar__skeleton">
                        <Skeleton count={10} />
                    </div>
                ) : (
                    <>
                        {sidebarRenderFormat ===
                        TopicsSidebarRenderFormats.TREE ? (
                            <TopicFolderTree
                                structure={filteredFolders}
                                customItem={(
                                    topicData: TopicInterfaceDetailed
                                ) => (
                                    <TopicItem
                                        topic={topicData}
                                        isSelected={
                                            topicData.id === pinedTopic?.id
                                        }
                                        lite={lite}
                                        onPin={(topic) =>
                                            handlePinedTopic(topic.id)
                                        }
                                        onClickIcon={(topic) =>
                                            handlePinedTopic(topic.id)
                                        }
                                        onUpdateTopic={getTopics}
                                        handleSearchesList={(topic) =>
                                            setSavedSearches({
                                                topic,
                                            })
                                        }
                                    />
                                )}
                                showActions={!lite}
                                expandedFolders={openedFolders}
                                expandAllFolders={Boolean(searchTerm)}
                                onUpdateFolder={onUpdateFolder}
                                onDeleteFolder={onDeleteFolder}
                                onExpandFolder={openFolder}
                                onCreateFolderItem={onUpdateTopic}
                                onCreateSubfolder={onCreateSubfolder}
                                onMoveItem={updateTopicInTree}
                            />
                        ) : (
                            <TopicsList
                                topics={filteredTopics}
                                lite={lite}
                                selectedItems={pinedTopic ? [pinedTopic] : []}
                                onSelectTopic={(topic) =>
                                    handlePinedTopic(topic.id)
                                }
                                handleSearchesList={(topic) =>
                                    setSavedSearches({
                                        topic,
                                    })
                                }
                                emptyMsg={
                                    intl.formatMessage({
                                        id: searchTerm
                                            ? 'no_topics_were_found'
                                            : 'no_topics_created_yet',
                                    }) + '...'
                                }
                                onUpdateTopic={getTopics}
                            />
                        )}
                    </>
                )}

                {}
            </div>
        </div>
    );
};

export default TopicsSidebar;

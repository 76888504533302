import { useEffect, useRef, KeyboardEvent, useState, createRef } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import classNames from 'classnames';
import sanitizeHtmlContent from '../../helpers/sanitizeHtmlContent';
import styles from './EditableBox.module.scss';

interface EditableBoxProps {
    value: string;
    isActive: boolean;
    disabled?: boolean;
    onFocus?: () => void;
    onSave: (value: string) => void;
}

const EditableBox = ({
    value,
    isActive,
    disabled,
    onSave,
    onFocus,
}: EditableBoxProps) => {
    // used to prevent double section saving when pressing 'Enter'
    const [enterPressed, setEnterPressed] = useState(false);

    const text = useRef(value);

    const input = createRef<HTMLElement>();

    useEffect(() => {
        if (isActive && input.current) input.current.focus();
    }, []);

    const onContentChange = (e: ContentEditableEvent) => {
        const value = e.currentTarget.innerHTML;
        text.current = sanitizeHtmlContent(value);
    };

    const onBlur = () => {
        if (!enterPressed) onSave(text.current);
        setEnterPressed(false);
    };

    const onPressEnter = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onSave(text.current);
            setEnterPressed(true);
        }
    };

    return (
        <div
            className={classNames(styles.cgEditableBox, {
                [styles['cgEditableBox__active']]: isActive,
                [styles['cgEditableBox__disabled']]: disabled,
            })}
        >
            <span className={styles.cgEditableBox__bullet}> · </span>

            <ContentEditable
                innerRef={input}
                html={text.current}
                className={styles.cgEditableBox__input}
                disabled={disabled}
                onChange={onContentChange}
                onFocus={() => onFocus?.()}
                onBlur={onBlur}
                onKeyDown={onPressEnter}
            />
        </div>
    );
};

export default EditableBox;

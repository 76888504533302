import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { useEntityContext } from '../../hooks';
import { ProjectContext } from '../../context/project';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import {
    ReportHeader,
    ReportsPlaceholder,
    ReportWriter,
} from '~/modules/Reports/components';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import useReports from '~/modules/Reports/hooks/useReports';
import styles from './ReportPage.module.scss';

const Reports = () => {
    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [loading, reports, fetchSections, fetchReports] = useReportsStore(
        useShallow((state) => [
            state.loading,
            state.reports,
            state.fetchSections,
            state.fetchReports,
        ])
    );

    const { selectedReport, resetMainStates } = useReports();

    useEffect(() => {
        if (!selectedProject) {
            if (reports.length) {
                resetMainStates();
            }
            return;
        }

        fetchReports(selectedProject.id);
    }, [selectedProject?.id]);

    useEffect(() => {
        if (!selectedReport) return;
        fetchSections(selectedReport.id);
    }, [selectedReport]);

    return (
        <div className={styles.cgReports}>
            {!loading && (
                <>
                    {selectedReport ? (
                        <div className={styles.cgReports__container}>
                            <ReportHeader />

                            <ReportWriter />
                        </div>
                    ) : (
                        <ReportsPlaceholder />
                    )}
                </>
            )}
        </div>
    );
};

export default Reports;

import {
    BLOCK_TYPES,
    SEARCH_OPERATORS,
    TERM_TYPE,
} from '~/modules/Search/types/search.enum';
import {
    ConditionsPresentation,
    ExpressionField,
    SearchBlockInterface,
    TermField,
} from '~/modules/Search/types/search.interface';

const builtSidePart = (block: SearchBlockInterface) => {
    let baseObject: TermField = {
        term_type: TERM_TYPE.TERM,
        value: '',
        negative: false,
    };

    if (!block) return baseObject;

    if (block.type === BLOCK_TYPES.text) {
        baseObject = {
            term_type: TERM_TYPE.TERM,
            value: block.value as string,
            negative: block.negative as boolean,
        };
    }

    if (block.type === BLOCK_TYPES.expression) {
        baseObject =
            block.content?.length > 1
                ? createRecursiveObject(block.content)
                : builtSidePart(block.content[0]);
    }

    return baseObject;
};

const createRecursiveObject = (
    keys: SearchBlockInterface[]
): ExpressionField => {
    return {
        term_type: TERM_TYPE.EXPRESSION,
        operator: keys[1].value as SEARCH_OPERATORS,
        left: builtSidePart(keys[0]),
        right: builtSidePart(keys[2]),
    };
};

const buildExpressionsPayload = (
    keys: SearchBlockInterface[]
): ConditionsPresentation | undefined => {
    if (!keys?.length) return undefined;

    if (keys.length === 1) return { expression: builtSidePart(keys[0]) };

    return { expression: createRecursiveObject(keys) };
};

export default buildExpressionsPayload;

import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { ReportInterface } from '~/interfaces/entities';
import { exportReportInTXT } from '~/modules/Reports/helpers/reportDownload';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { TopicsContext } from '~/context/topics';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import useReports from '~/modules/Reports/hooks/useReports';
import useReportsStore from '~/modules/Reports/stores/reportsStore';
import downloadBlob from '~/utils/downloadBlob';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import downloadPPTReport from './downloadPPTReport';

const useReportDownload = () => {
    const intl = useIntl();

    const downloadMenu = useFloatMenu();

    const { topics } = useEntityContext<TopicsProviderInterface>(TopicsContext);
    const { selectedWorkspace } = useWorkspaces();

    const { selectedReport } = useReports();

    const sections = useReportsStore((state) => state.sections);

    const onDownloadPDF = async (blob: Blob) => {
        downloadBlob(blob, `Cogny-Report-${selectedReport?.name}.pdf`);
        onDownloadSuccess();
    };

    const onDownloadRawText = () => {
        exportReportInTXT(
            selectedReport as ReportInterface,
            sections,
            topics,
            selectedWorkspace?.id as string
        );

        onDownloadSuccess();
    };

    const onDownloadPPT = () => {
        downloadPPTReport({
            report: selectedReport as ReportInterface,
            topics,
            sections,
            workspaceId: selectedWorkspace?.id as string,
            callback: onDownloadSuccess,
        });
    };

    const onDownloadSuccess = () => {
        downloadMenu.handleMenu();
        toast.success(
            intl.formatMessage({ id: 'report_downloaded_successfully' })
        );
    };

    return {
        downloadMenu,
        onDownloadPDF,
        onDownloadRawText,
        onDownloadPPT,
    };
};

export default useReportDownload;

import { SearchBarFiltersInterface } from '~/modules/Search/types/search.interface';
import { Pill } from '~/components/UIElements';
import { useIntl } from 'react-intl';
import useSearchStore from '~/modules/Search/stores/searchStore';
import { useEntityContext } from '~/hooks';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { TagsContext } from '~/context/tags';
import { TagInterface } from '~/modules/Tags/types/Tag.interface';
import { CGDocumentInterface } from '~/interfaces/entities';

import styles from './SearchFiltersDisplay.module.scss';
import { Tooltip } from 'react-tooltip';
import cutStringLength from '../../../../../utils/cutStringLength';

const SearchFiltersDisplay = ({
    filters,
}: {
    filters: SearchBarFiltersInterface;
}) => {
    const intl = useIntl();
    const files = useSearchStore((state) => state.files);
    const { tags } = useEntityContext<TagsProviderInterface>(TagsContext);

    const getFilterDetails = (
        items: CGDocumentInterface[] | TagInterface[],
        filterIds?: string[]
    ) => {
        if (!filterIds) return null;

        const filteredItems = items.filter((item) =>
            filterIds.includes(item.id)
        );
        const label = intl.formatMessage({
            id: filteredItems.length > 1 ? 'values' : 'value',
        });

        return {
            value: `${filteredItems.length} ${label}`,
            tooltip: filteredItems
                .map((item) => cutStringLength(item.name, 20))
                .join(', '),
        };
    };

    const {
        filter_document_ids,
        filter_tag_ids,
        filter_section_page_numbers,
        case_sensitive,
        exact_match,
    } = filters;

    const tagFilters = getFilterDetails(tags, filter_tag_ids);

    const fileFilters = getFilterDetails(files, filter_document_ids);

    const pageFilters = filter_section_page_numbers
        ? {
              value: `${filter_section_page_numbers.length} ${intl.formatMessage(
                  {
                      id:
                          filter_section_page_numbers.length > 1
                              ? 'values'
                              : 'value',
                  }
              )}`,
              tooltip: filter_section_page_numbers.join(', '),
          }
        : null;

    return (
        <div className={styles.cgSearchFiltersDisplay}>
            {pageFilters && (
                <>
                    <Pill
                        className={styles.cgSearchFiltersDisplay__pill}
                        prefix={intl.formatMessage({ id: 'page' }) + ':'}
                        data-tooltip-id="search-page-filter"
                        data-tooltip-content={pageFilters.tooltip}
                    >
                        <span>{pageFilters.value}</span>
                    </Pill>
                    <Tooltip
                        opacity={1}
                        place="bottom"
                        noArrow
                        id="search-page-filter"
                    />
                </>
            )}

            {fileFilters && (
                <>
                    <Pill
                        className={styles.cgSearchFiltersDisplay__pill}
                        prefix={intl.formatMessage({ id: 'file' }) + ':'}
                        data-tooltip-id="search-file-filter"
                        data-tooltip-content={fileFilters.tooltip}
                    >
                        <span>{fileFilters.value}</span>
                    </Pill>
                    <Tooltip
                        opacity={1}
                        place="bottom"
                        noArrow
                        id="search-file-filter"
                    />
                </>
            )}

            {tagFilters && (
                <>
                    <Pill
                        className={styles.cgSearchFiltersDisplay__pill}
                        prefix={intl.formatMessage({ id: 'tag' }) + ':'}
                        data-tooltip-id="search-tag-filter"
                        data-tooltip-content={tagFilters.tooltip}
                    >
                        <span>{tagFilters.value}</span>
                    </Pill>
                    <Tooltip
                        opacity={1}
                        place="bottom"
                        noArrow
                        id="search-tag-filter"
                    />
                </>
            )}

            {case_sensitive && (
                <Pill>{intl.formatMessage({ id: 'case_sensitive' })}</Pill>
            )}

            {exact_match && (
                <Pill>{intl.formatMessage({ id: 'exact_match' })}</Pill>
            )}
        </div>
    );
};

export default SearchFiltersDisplay;

import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    section: {
        marginVertical: 10,
    },
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        fontFamily: 'Antonia',
    },
    text: {
        fontFamily: 'Instrument',
        fontSize: 12,
    },
    view: {
        marginTop: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    link: {
        marginHorizontal: 5,
        borderWidth: '1px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        paddingHorizontal: 5,
        paddingVertical: 3,
        color: 'black',
        textDecoration: 'none',
        fontSize: 10,
        display: 'inline-block',
        marginBottom: 5,
    },
    plainLink: {
        fontSize: 12,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',

        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
    },
    footerText: {
        fontSize: 8,
        color: 'grey',
    },
    logo: {
        marginLeft: -30,
        width: 100,
        height: 'auto',
    },
});

import { useMemo } from 'react';
import { Edit3, MoreHorizontal, Trash2 } from 'react-feather';
import {
    BasePopover,
    BasicMenuList,
    DeleteConfirmationDialog,
} from '~/components/UIElements';
import { useFloatMenu } from '~/hooks';
import { reportServices } from '~/services';
import { toast } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import RestrictedUI from '~/components/RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { ReportInterface } from '~/interfaces/entities';
import { useIntl } from 'react-intl';
import { getErrorCode } from '~/utils/getErrorCode';
import classNames from 'classnames';

import styles from './ReportItem.module.scss';

interface ReportItemProps {
    report: ReportInterface;
    isSelected: boolean;
    onSelect: (report: ReportInterface) => void;
    handleReportToEdit: () => void;
    deleteCallback: () => void;
}

const ReportItem = ({
    report,
    isSelected,
    onSelect,
    handleReportToEdit,
    deleteCallback,
}: ReportItemProps) => {
    const intl = useIntl();

    const actionsMenu = useFloatMenu();
    const deleteMenu = useFloatMenu();

    const MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'rename' }),
                onClick: () => {
                    actionsMenu.setShowMenu(false);
                    handleReportToEdit();
                },
                icon: Edit3,
            },
            {
                label: intl.formatMessage({ id: 'delete' }),
                onClick: () => {
                    actionsMenu.setShowMenu(false);
                    deleteMenu.setShowMenu(true);
                },
                icon: Trash2,
                variant: 'distructive',
            },
        ],
        []
    );

    const onDeleteReport = async () => {
        const [error, response] = await reportServices.deleteReport(report.id);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        toast.success(
            intl.formatMessage({ id: 'report_deleted_successfully' })
        );

        deleteCallback();
    };

    return (
        <>
            {deleteMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteMenu.handleMenu}
                    callback={onDeleteReport}
                    title={intl.formatMessage({ id: 'delete_report' }) + '?'}
                    text={intl.formatMessage({
                        id: 'this_action_cannot_be_undone',
                    })}
                    buttonText={intl.formatMessage({ id: 'delete' })}
                />
            )}
            <div
                className={classNames(styles.cgReportItem, {
                    [styles.cgReportItem__selected]:
                        isSelected || actionsMenu.showMenu,
                })}
                onClick={() => onSelect(report)}
                data-tooltip-id={report.id}
                data-tooltip-content={report.name}
                data-tooltip-delay-show={1000}
            >
                <Tooltip
                    id={report.id}
                    place="bottom"
                    opacity={1}
                    className={styles.cgReportItem__tooltip}
                />

                <p className={styles.cgReportItem__label}>{report.name}</p>

                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.REPORTS}>
                    <BasePopover
                        isOpen={actionsMenu.showMenu}
                        positions={['bottom', 'left', 'right']}
                        align="end"
                        content={<BasicMenuList menus={MENUS} />}
                        onClickOutside={() => actionsMenu.setShowMenu(false)}
                    >
                        <MoreHorizontal
                            onClick={(e) => {
                                e.stopPropagation();
                                actionsMenu.setShowMenu(true);
                            }}
                            className={styles.cgReportItem__menuToggle}
                        />
                    </BasePopover>
                </RestrictedUI>
            </div>
        </>
    );
};

export default ReportItem;

import { useMemo, useState } from 'react';
import { Button, TextAreaField } from '~/components/UIElements';
import { TopicsContext } from '~/context/topics';
import { searchServices } from '~/services';
import { ProjectContext } from '~/context/project';
import { toast } from 'react-hot-toast';
import { useEntityContext } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { useIntl } from 'react-intl';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';
import TopicSelectorMenu from '~/modules/Topics/components/TopicSelectorMenu';
import { SAVED_SEARCH_TYPES } from '~/modules/Search/types/search.enum';
import { AISearchInterface } from '~/modules/Search/types/search.interface';
import { SearchPillsFilters } from '~/modules/Search/types/savedSearch.interface';
import { getSavedSearchFiltersPayload } from '~/modules/Search/helpers/searchFiltersHelpers';

import './SaveAISearchDialog.scss';
export interface AISearchToSaveInterface {
    ai_search: AISearchInterface;
    topic_id?: string | null;
    id?: string;
    search_type: SAVED_SEARCH_TYPES;
    filters: SearchPillsFilters;
}

interface SaveAISearchDialogProps {
    searchToSave: AISearchToSaveInterface;
    callback?: () => void;
    isEditing?: boolean;
    title?: string;
}

const SaveAISearchDialog: React.FC<SaveAISearchDialogProps> = ({
    searchToSave,
    callback,
    isEditing,
    title,
}) => {
    const intl = useIntl();

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { topics, getTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState<AISearchToSaveInterface>(searchToSave);

    const selectedTopic = useMemo(
        () => topics.find((t) => t.id === search.topic_id) || null,
        [topics, search.topic_id]
    );

    const onSubmit = async () => {
        setLoading(true);
        const { topic_id, filters } = search;

        const projectId = topic_id ? null : (selectedProject?.id as string);
        const searchBarFilters = getSavedSearchFiltersPayload(filters);

        const payload = {
            ...search,
            ...searchBarFilters,
            project_id: projectId,
            topic_id,
            name: 'default',
            ai_search: {
                prompt: search.ai_search.prompt,
                project_ids: projectId ? [projectId] : [],
            },
        };

        const [saveSearchError] = isEditing
            ? await searchServices.updateSearch(payload, searchToSave.id)
            : await searchServices.saveSearch(payload);

        setLoading(false);
        if (saveSearchError)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(
            intl.formatMessage({
                id: `search_${isEditing ? 'updated' : 'saved'}_successfully`,
            })
        );

        callback && callback();
        getTopics();
    };

    const onSelectTopic = (topic: TopicInterface | null) => {
        setSearch({
            ...search,
            topic_id: topic?.id || null,
        });
    };

    return (
        <div className="cg-ai-search-dialog animate__animated animate__fadeIn animate__faster">
            {title && (
                <div className="cg-ai-search-dialog__header">
                    <h4>{title}</h4>
                </div>
            )}

            <div className="cg-ai-search-dialog__body">
                <TextAreaField
                    label={intl.formatMessage({ id: 'prompt' })}
                    inputProps={{
                        disabled: true,
                        value: search.ai_search.prompt,
                    }}
                />

                <TopicSelectorMenu
                    intl={intl}
                    onSelectTopic={onSelectTopic}
                    selectedTopic={selectedTopic}
                    placeholder={
                        intl.formatMessage({ id: 'search_topics' }) + '...'
                    }
                    label={intl.formatMessage({ id: 'link_to_topic' })}
                    isOptional={true}
                />

                <Button disabled={loading} variant="accent" onClick={onSubmit}>
                    {intl.formatMessage({ id: 'save' })}
                </Button>
            </div>
        </div>
    );
};

export default SaveAISearchDialog;

import { documentServices } from '~/services';
import { SearchStoreInterface } from '../types/searchStore.interface';

export const fetchFiles =
    (set: (state: Partial<SearchStoreInterface>) => void) =>
    async (projectId: string) => {
        const [error, response] = await documentServices.getDocuments(
            {
                all: true,
                project_ids: projectId,
            },
            true
        );

        if (error) {
            console.error(error);
            return;
        }

        set({ files: response.data });
    };

export const resetFilters =
    (set: (state: Partial<SearchStoreInterface>) => void) => () => {
        set({
            filters: {
                case_sensitive: false,
                exact_match: false,
            },
        });
    };

import {
    Text,
    Document,
    Page,
    View,
    Font,
    Image,
    Link,
} from '@react-pdf/renderer';
import antoniaFont from '~/assets/fonts/antonia/Antonia-Regular.otf';
import instrumentFont from '~/assets/fonts/instrument/InstrumentSans-Regular.ttf';
import cognyLogo from '~/assets/images/cogny-logo.png';
import { styles } from './ExportableReport.styles';
import {
    ReportInterface,
    ReportSectionInterfaceDetailed,
} from '~/interfaces/entities';
import getReportSourcesText from '~/modules/Reports/helpers/getReportSourcesText';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import { splitMarkdownInURL } from '../../helpers/splitMarkdownInURL';
import { REPORT_LINK_OPTIONS } from '~/modules/Reports/constants/reports.enum';

// Register font
Font.register({ family: 'Antonia', src: antoniaFont });
Font.register({ family: 'Instrument', src: instrumentFont });

interface ExportableReportProps {
    selectedReport: ReportInterface;
    sections: ReportSectionInterfaceDetailed[];
    topics: TopicInterfaceDetailed[];
    workspaceId: string;
}

// this component is used to be used as template to export PDFs
const ExportableReport = ({
    selectedReport,
    sections,
    topics,
    workspaceId,
}: ExportableReportProps) => {
    const { parsedConclusion, generatedLinks } = splitMarkdownInURL(
        selectedReport.conclusion,
        REPORT_LINK_OPTIONS.PDF
    );

    return (
        <Document>
            <Page style={styles.page}>
                <Text style={styles.title}> {selectedReport.name} </Text>
                {sections.map((section, index) => (
                    <View style={styles.section} key={index}>
                        <Text style={styles.text}>{section.text} </Text>
                        <View style={styles.view}>
                            {getReportSourcesText(
                                section,
                                topics,
                                selectedReport.project_id,
                                workspaceId
                            ).map((src, idx) => (
                                <Link
                                    key={idx}
                                    style={styles.link}
                                    src={src.queryLink}
                                >
                                    {src.rawText}
                                </Link>
                            ))}
                        </View>
                    </View>
                ))}

                <View fixed style={styles.footer}>
                    <Text style={styles.footerText}>Powered by</Text>
                    <Image style={styles.logo} src={cognyLogo} />
                </View>
            </Page>
            {parsedConclusion.length > 0 && (
                <Page style={styles.page}>
                    <View style={styles.section}>
                        {parsedConclusion.map((c, idx) => (
                            <Text key={idx} style={styles.text}>
                                {c}
                                {generatedLinks[idx] || ''}
                            </Text>
                        ))}
                    </View>
                    <View fixed style={styles.footer}>
                        <Text style={styles.footerText}>Powered by</Text>
                        <Image style={styles.logo} src={cognyLogo} />
                    </View>
                </Page>
            )}
        </Document>
    );
};

export default ExportableReport;

import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import { ReportsStoreInterface } from '~/modules/Reports/types/reportsStore.interface';
import { reportServices } from '~/services';
import { getQueryParams } from '~/utils/getQueryParams';

const queryParams = getQueryParams<{ report: string }>('params');

export const fetchReports =
    (
        set: (state: Partial<ReportsStoreInterface>) => void,
        get: () => ReportsStoreInterface
    ) =>
    async (projectId: string) => {
        set({ loading: true });

        const [error, response] = await reportServices.getReports({
            all: true,
            project_ids: projectId,
        });

        set({ loading: false });

        if (error) return;

        const { data } = response;

        set({ reports: data });

        // check if user comes from a query link
        if (queryParams?.report) {
            const queryReport = data.find((p) => p.id === queryParams.report);
            queryReport && set({ selectedReport: queryReport.id });
            return;
        }

        const selectedReport = get().selectedReport;

        if (!selectedReport && data.length) {
            set({ selectedReport: data[0].id });
        }
    };

export const fetchSections =
    (
        set: (state: Partial<ReportsStoreInterface>) => void,
        get: () => ReportsStoreInterface
    ) =>
    async (reportId?: string) => {
        set({
            loadingSections: true,
        });

        const selectedReportId = reportId || get().selectedReport;

        const [error, response] = await reportServices.getReportSections({
            report_ids: selectedReportId,
            all: true,
            sort_attribute: 'position',
            sort_order: SORT_TYPE_OPTIONS.DESC,
        });

        set({
            loadingSections: false,
        });

        if (error) return;

        const { data } = response;
        set({
            sections: data,
        });
    };

import { useIntl } from 'react-intl';
import { Button, Pill } from '~/components/UIElements';
import { Plus } from 'react-feather';
import RestrictedUI from '~/components/RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { InsightInterface } from '~/interfaces/entities';

import styles from './SourcesStack.module.scss';

interface SourcesStackProps {
    sources?: { [key: string]: InsightInterface[] };
    onSelectSource: (comments: InsightInterface[], isNew: boolean) => void;
}

const SourcesStack = ({ sources = {}, onSelectSource }: SourcesStackProps) => {
    const intl = useIntl();
    const src = Object.keys(sources); // topics from each Conclusion source

    const getSourceText = (topicName: string) => {
        const count = sources[topicName].length;

        return `${topicName} ${intl.formatMessage(
            {
                id: count > 1 ? 'subset_rows' : 'subset_row',
            },
            { count }
        )}`;
    };

    return (
        <div className={styles.cgSourcesStack}>
            {src.map((topic) => (
                <Pill
                    onClick={() => onSelectSource(sources[topic], false)}
                    key={topic}
                    clickable
                    className={styles.cgSourcesStack__source}
                >
                    <span>{getSourceText(topic)}</span>
                </Pill>
            ))}
            <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.SOURCES}>
                <Button
                    onClick={() => onSelectSource([], true)}
                    variant="secondary"
                    iconBefore={Plus}
                    className={styles.cgSourcesStack__add}
                >
                    {intl.formatMessage({ id: 'add_source' })}
                </Button>
            </RestrictedUI>
        </div>
    );
};

export default SourcesStack;

const downloadBlob = (blob: Blob, fileName: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    // Clean up
    document.body.removeChild(downloadLink);
};

export default downloadBlob;

import { useRef } from 'react';
import './SearchRow.scss';
import { Edit3, MoreHorizontal, Trash2 } from 'react-feather';
import {
    BasePopover,
    BasicMenuList,
    IconButton,
} from '~/components/UIElements';
import RestrictedUI from '~/components/RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { useIntl } from 'react-intl';
import { useFloatMenu } from '~/hooks';
import {
    ConditionsPresentation,
    SearchBarFiltersInterface,
} from '~/modules/Search/types/search.interface';
import { BasicMenuItemInterface } from '~/components/UIElements/BasicMenuList/MenuItem';
import SearchDisplay from '../SearchDisplay';
import SearchFiltersDisplay from '~/modules/Search/components/SearchRow/SearchFiltersDisplay';

interface SearchRowProps {
    filters?: SearchBarFiltersInterface;
    name: string;
    blocks?: ConditionsPresentation;
    onSelectSearch: () => void;
    handleDelete: () => void;
    handleEdit: () => void;
}

const SearchRow = ({
    name,
    filters,
    blocks,
    onSelectSearch,
    handleDelete,
    handleEdit,
}: SearchRowProps) => {
    const intl = useIntl();
    const menu = useFloatMenu();

    const EDIT_MENUS: BasicMenuItemInterface[] = [
        {
            label: intl.formatMessage({ id: 'edit' }),
            icon: Edit3,
            onClick: () => {
                handleEdit();
                menu.setShowMenu(false);
            },
        },
        {
            label: intl.formatMessage({ id: 'delete' }),
            onClick: () => {
                handleDelete();
                menu.setShowMenu(false);
            },
            icon: Trash2,
            variant: 'distructive',
        },
    ];

    const container = useRef();

    return (
        <div className="search-row" onClick={onSelectSearch}>
            <div className="search-row__name" ref={container}>
                <span>{name}</span>

                <RestrictedUI
                    entity={ENTITIES.SAVED_QUERIES}
                    to={PERMISSIONS.EDIT}
                >
                    <BasePopover
                        isOpen={menu.showMenu}
                        onClickOutside={() => menu.setShowMenu(false)}
                        positions={['bottom', 'top', 'left', 'right']}
                        content={<BasicMenuList menus={EDIT_MENUS} />}
                        align="start"
                        parentContainer={container.current}
                    >
                        <IconButton
                            icon={MoreHorizontal}
                            variant="tertiary"
                            onClick={(e) => {
                                e.stopPropagation();
                                menu.setShowMenu(!menu.showMenu);
                            }}
                        />
                    </BasePopover>
                </RestrictedUI>
            </div>
            {blocks && <SearchDisplay blocks={blocks} />}
            {filters && <SearchFiltersDisplay filters={filters} />}
        </div>
    );
};

export default SearchRow;

import { Link } from '@react-pdf/renderer';
import { styles } from '../components/ExportableReport/ExportableReport.styles';
import { REPORT_LINK_OPTIONS } from '../constants/reports.enum';

/**
 *
 * @param {string} string report's conclusion
 * @param {REPORT_LINK_OPTIONS} linkOption Available link options
 * @returns {{parsedConclusion: string[], generatedLinks: any[]}}
 */
export const splitMarkdownInURL = (
    string = '',
    linkOption: REPORT_LINK_OPTIONS
) => {
    // Regular expression to match URLs in Markdown
    const urlRegex =
        /\[(?<displayText>[^\]]+)\]\((?<link>https?:\/\/[^\s]+)\)/g;

    /**
     * 'Link' components for each found URL
     */
    const generatedLinks = [];
    const FLAG = '$_#FLAG#_$';

    // Replace every url match for a flag while creating a link component that is
    // to be replaced at the flag's position.
    const flaggedText = string.replace(urlRegex, (_, displayText, link) => {
        if (linkOption === REPORT_LINK_OPTIONS.PDF) {
            generatedLinks.push(
                <Link
                    key={crypto.randomUUID()}
                    style={styles.plainLink}
                    src={link}
                >
                    {displayText}
                </Link>
            );
        } else if (linkOption === REPORT_LINK_OPTIONS.PPT) {
            generatedLinks.push({
                text: displayText,
                options: {
                    fontSize: 10,
                    hyperlink: {
                        url: link,
                    },
                },
            });
        }

        return FLAG;
    });
    return { parsedConclusion: flaggedText.split(FLAG), generatedLinks };
};
